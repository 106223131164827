import { default as editQOdYrEbXWVMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/devices/[id]/edit.vue?macro=true";
import { default as indexBNu1OpnyVqMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/devices/[id]/index.vue?macro=true";
import { default as createpu53yrSQBBMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/devices/create.vue?macro=true";
import { default as indexBrmdLWamWGMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/devices/index.vue?macro=true";
import { default as editbirWtPXbsSMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/external-services/[id]/edit.vue?macro=true";
import { default as createxnGdWSX4I0Meta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/external-services/create.vue?macro=true";
import { default as indexP46RDn5VUsMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/external-services/index.vue?macro=true";
import { default as editWwGl0oG57KMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/users/[id]/[company_id]/edit.vue?macro=true";
import { default as create1eJj2NZnuEMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/users/create.vue?macro=true";
import { default as indexIV44vZOj0JMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/users/index.vue?macro=true";
import { default as indexQ1gLKbIoWlMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/dashboard/index.vue?macro=true";
import { default as indexZwSKcSQN33Meta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/index.vue?macro=true";
import { default as loginfaz1VktXciMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/login.vue?macro=true";
import { default as profileyiSaOJnVJzMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/profile.vue?macro=true";
import { default as indexB2bXveSG8DMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/calendar/index.vue?macro=true";
import { default as indexRBBlWIcSXJMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/exceptional/[waste_collection_routes_exceptional_id]/index.vue?macro=true";
import { default as indexJj70hCz378Meta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/exceptional/index.vue?macro=true";
import { default as editNHvAaAAi0mMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/regular/[waste_collection_routes_regular_id]/edit.vue?macro=true";
import { default as index4TeYyROrotMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/regular/[waste_collection_routes_regular_id]/index.vue?macro=true";
import { default as indexVbaijYIODSMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/regular/[waste_collection_routes_regular_id]/waste-collections/[collection_id]/index.vue?macro=true";
import { default as createRkSJjnYpEVMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/regular/create.vue?macro=true";
import { default as indexLGJhBHvEWLMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/regular/index.vue?macro=true";
import { default as edit38hlVpqM4jMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-companies/[id]/edit.vue?macro=true";
import { default as indexgY6EaFUYNEMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-companies/[id]/index.vue?macro=true";
import { default as editSuzEOClPlvMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-companies/[id]/users/[user_id]/edit.vue?macro=true";
import { default as createCrYcFkNnGXMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-companies/[id]/users/create.vue?macro=true";
import { default as createIdrulUuc7eMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-companies/create.vue?macro=true";
import { default as indexGgk2pZscR9Meta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-companies/index.vue?macro=true";
import { default as edit3JY4BBYKfyMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/cars/[id]/edit.vue?macro=true";
import { default as indexIFP7W5YVpZMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/cars/[id]/index.vue?macro=true";
import { default as createRkgLmnl32dMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/cars/create.vue?macro=true";
import { default as indexnb4g0nJGzjMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/cars/index.vue?macro=true";
import { default as editoW8td2PloOMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/devices/[id]/edit.vue?macro=true";
import { default as indexZrVFzV82saMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/devices/[id]/index.vue?macro=true";
import { default as createcaTFP2oQyvMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/devices/create.vue?macro=true";
import { default as indexfiYR6bHN3tMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/devices/index.vue?macro=true";
import { default as editOJaUnJZR7qMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/external-services/[id]/edit.vue?macro=true";
import { default as createMaOINI03OmMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/external-services/create.vue?macro=true";
import { default as index2scMb3p5JMMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/external-services/index.vue?macro=true";
import { default as editvpnEV5IvLPMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/workers/[id]/edit.vue?macro=true";
import { default as indexrfO7swxPpDMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/workers/[id]/index.vue?macro=true";
import { default as createxZhnw8n2SbMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/workers/create.vue?macro=true";
import { default as indexnVHn63WnOfMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/workers/index.vue?macro=true";
import { default as editEbNGPWYxyGMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/[id]/edit.vue?macro=true";
import { default as index1RS8uJMzKPMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/[id]/index.vue?macro=true";
import { default as edit0hdKME44ecMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/[id]/subjects/[subject_id]/edit.vue?macro=true";
import { default as indexCjX3iDzjRpMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/[id]/subjects/[subject_id]/index.vue?macro=true";
import { default as createph9I3Ht6uZMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/[id]/subjects/create.vue?macro=true";
import { default as editkmPBhkhfYeMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/[id]/waste-customer-contracts/[contract_id]/edit.vue?macro=true";
import { default as create6ZS1EqHKnvMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/[id]/waste-customer-contracts/create.vue?macro=true";
import { default as createuAlcYc2RWRMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/create.vue?macro=true";
import { default as exportjMwpNGWI7SMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/export.vue?macro=true";
import { default as importF1VPMM6XQvMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/import.vue?macro=true";
import { default as indexGHtJEMOhiGMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/index.vue?macro=true";
import { default as edit6t7aHxig6WMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-evidence-headers/[id]/edit.vue?macro=true";
import { default as indexvXfFBpRxVxMeta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-evidence-headers/[id]/index.vue?macro=true";
import { default as createPonLRZ3iR5Meta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-evidence-headers/create.vue?macro=true";
import { default as indexeygS8Yo372Meta } from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-evidence-headers/index.vue?macro=true";
export default [
  {
    name: editQOdYrEbXWVMeta?.name ?? "administration-devices-id-edit",
    path: editQOdYrEbXWVMeta?.path ?? "/administration/devices/:id()/edit",
    meta: editQOdYrEbXWVMeta || {},
    alias: editQOdYrEbXWVMeta?.alias || [],
    redirect: editQOdYrEbXWVMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/devices/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexBNu1OpnyVqMeta?.name ?? "administration-devices-id",
    path: indexBNu1OpnyVqMeta?.path ?? "/administration/devices/:id()",
    meta: indexBNu1OpnyVqMeta || {},
    alias: indexBNu1OpnyVqMeta?.alias || [],
    redirect: indexBNu1OpnyVqMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/devices/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createpu53yrSQBBMeta?.name ?? "administration-devices-create",
    path: createpu53yrSQBBMeta?.path ?? "/administration/devices/create",
    meta: createpu53yrSQBBMeta || {},
    alias: createpu53yrSQBBMeta?.alias || [],
    redirect: createpu53yrSQBBMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/devices/create.vue").then(m => m.default || m)
  },
  {
    name: indexBrmdLWamWGMeta?.name ?? "administration-devices",
    path: indexBrmdLWamWGMeta?.path ?? "/administration/devices",
    meta: indexBrmdLWamWGMeta || {},
    alias: indexBrmdLWamWGMeta?.alias || [],
    redirect: indexBrmdLWamWGMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/devices/index.vue").then(m => m.default || m)
  },
  {
    name: editbirWtPXbsSMeta?.name ?? "administration-external-services-id-edit",
    path: editbirWtPXbsSMeta?.path ?? "/administration/external-services/:id()/edit",
    meta: editbirWtPXbsSMeta || {},
    alias: editbirWtPXbsSMeta?.alias || [],
    redirect: editbirWtPXbsSMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/external-services/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createxnGdWSX4I0Meta?.name ?? "administration-external-services-create",
    path: createxnGdWSX4I0Meta?.path ?? "/administration/external-services/create",
    meta: createxnGdWSX4I0Meta || {},
    alias: createxnGdWSX4I0Meta?.alias || [],
    redirect: createxnGdWSX4I0Meta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/external-services/create.vue").then(m => m.default || m)
  },
  {
    name: indexP46RDn5VUsMeta?.name ?? "administration-external-services",
    path: indexP46RDn5VUsMeta?.path ?? "/administration/external-services",
    meta: indexP46RDn5VUsMeta || {},
    alias: indexP46RDn5VUsMeta?.alias || [],
    redirect: indexP46RDn5VUsMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/external-services/index.vue").then(m => m.default || m)
  },
  {
    name: editWwGl0oG57KMeta?.name ?? "administration-users-id-company_id-edit",
    path: editWwGl0oG57KMeta?.path ?? "/administration/users/:id()/:company_id()/edit",
    meta: editWwGl0oG57KMeta || {},
    alias: editWwGl0oG57KMeta?.alias || [],
    redirect: editWwGl0oG57KMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/users/[id]/[company_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: create1eJj2NZnuEMeta?.name ?? "administration-users-create",
    path: create1eJj2NZnuEMeta?.path ?? "/administration/users/create",
    meta: create1eJj2NZnuEMeta || {},
    alias: create1eJj2NZnuEMeta?.alias || [],
    redirect: create1eJj2NZnuEMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/users/create.vue").then(m => m.default || m)
  },
  {
    name: indexIV44vZOj0JMeta?.name ?? "administration-users",
    path: indexIV44vZOj0JMeta?.path ?? "/administration/users",
    meta: indexIV44vZOj0JMeta || {},
    alias: indexIV44vZOj0JMeta?.alias || [],
    redirect: indexIV44vZOj0JMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/administration/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ1gLKbIoWlMeta?.name ?? "dashboard",
    path: indexQ1gLKbIoWlMeta?.path ?? "/dashboard",
    meta: indexQ1gLKbIoWlMeta || {},
    alias: indexQ1gLKbIoWlMeta?.alias || [],
    redirect: indexQ1gLKbIoWlMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexZwSKcSQN33Meta?.name ?? "index",
    path: indexZwSKcSQN33Meta?.path ?? "/",
    meta: indexZwSKcSQN33Meta || {},
    alias: indexZwSKcSQN33Meta?.alias || [],
    redirect: indexZwSKcSQN33Meta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginfaz1VktXciMeta?.name ?? "login",
    path: loginfaz1VktXciMeta?.path ?? "/login",
    meta: loginfaz1VktXciMeta || {},
    alias: loginfaz1VktXciMeta?.alias || [],
    redirect: loginfaz1VktXciMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/login.vue").then(m => m.default || m)
  },
  {
    name: profileyiSaOJnVJzMeta?.name ?? "profile",
    path: profileyiSaOJnVJzMeta?.path ?? "/profile",
    meta: profileyiSaOJnVJzMeta || {},
    alias: profileyiSaOJnVJzMeta?.alias || [],
    redirect: profileyiSaOJnVJzMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: indexB2bXveSG8DMeta?.name ?? "waste-collection-routes-calendar",
    path: indexB2bXveSG8DMeta?.path ?? "/waste-collection-routes/calendar",
    meta: indexB2bXveSG8DMeta || {},
    alias: indexB2bXveSG8DMeta?.alias || [],
    redirect: indexB2bXveSG8DMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: indexRBBlWIcSXJMeta?.name ?? "waste-collection-routes-exceptional-waste_collection_routes_exceptional_id",
    path: indexRBBlWIcSXJMeta?.path ?? "/waste-collection-routes/exceptional/:waste_collection_routes_exceptional_id()",
    meta: indexRBBlWIcSXJMeta || {},
    alias: indexRBBlWIcSXJMeta?.alias || [],
    redirect: indexRBBlWIcSXJMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/exceptional/[waste_collection_routes_exceptional_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJj70hCz378Meta?.name ?? "waste-collection-routes-exceptional",
    path: indexJj70hCz378Meta?.path ?? "/waste-collection-routes/exceptional",
    meta: indexJj70hCz378Meta || {},
    alias: indexJj70hCz378Meta?.alias || [],
    redirect: indexJj70hCz378Meta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/exceptional/index.vue").then(m => m.default || m)
  },
  {
    name: editNHvAaAAi0mMeta?.name ?? "waste-collection-routes-regular-waste_collection_routes_regular_id-edit",
    path: editNHvAaAAi0mMeta?.path ?? "/waste-collection-routes/regular/:waste_collection_routes_regular_id()/edit",
    meta: editNHvAaAAi0mMeta || {},
    alias: editNHvAaAAi0mMeta?.alias || [],
    redirect: editNHvAaAAi0mMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/regular/[waste_collection_routes_regular_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index4TeYyROrotMeta?.name ?? "waste-collection-routes-regular-waste_collection_routes_regular_id",
    path: index4TeYyROrotMeta?.path ?? "/waste-collection-routes/regular/:waste_collection_routes_regular_id()",
    meta: index4TeYyROrotMeta || {},
    alias: index4TeYyROrotMeta?.alias || [],
    redirect: index4TeYyROrotMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/regular/[waste_collection_routes_regular_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVbaijYIODSMeta?.name ?? "waste-collection-routes-regular-waste_collection_routes_regular_id-waste-collections-collection_id",
    path: indexVbaijYIODSMeta?.path ?? "/waste-collection-routes/regular/:waste_collection_routes_regular_id()/waste-collections/:collection_id()",
    meta: indexVbaijYIODSMeta || {},
    alias: indexVbaijYIODSMeta?.alias || [],
    redirect: indexVbaijYIODSMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/regular/[waste_collection_routes_regular_id]/waste-collections/[collection_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createRkSJjnYpEVMeta?.name ?? "waste-collection-routes-regular-create",
    path: createRkSJjnYpEVMeta?.path ?? "/waste-collection-routes/regular/create",
    meta: createRkSJjnYpEVMeta || {},
    alias: createRkSJjnYpEVMeta?.alias || [],
    redirect: createRkSJjnYpEVMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/regular/create.vue").then(m => m.default || m)
  },
  {
    name: indexLGJhBHvEWLMeta?.name ?? "waste-collection-routes-regular",
    path: indexLGJhBHvEWLMeta?.path ?? "/waste-collection-routes/regular",
    meta: indexLGJhBHvEWLMeta || {},
    alias: indexLGJhBHvEWLMeta?.alias || [],
    redirect: indexLGJhBHvEWLMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-collection-routes/regular/index.vue").then(m => m.default || m)
  },
  {
    name: edit38hlVpqM4jMeta?.name ?? "waste-companies-id-edit",
    path: edit38hlVpqM4jMeta?.path ?? "/waste-companies/:id()/edit",
    meta: edit38hlVpqM4jMeta || {},
    alias: edit38hlVpqM4jMeta?.alias || [],
    redirect: edit38hlVpqM4jMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-companies/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexgY6EaFUYNEMeta?.name ?? "waste-companies-id",
    path: indexgY6EaFUYNEMeta?.path ?? "/waste-companies/:id()",
    meta: indexgY6EaFUYNEMeta || {},
    alias: indexgY6EaFUYNEMeta?.alias || [],
    redirect: indexgY6EaFUYNEMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-companies/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: editSuzEOClPlvMeta?.name ?? "waste-companies-id-users-user_id-edit",
    path: editSuzEOClPlvMeta?.path ?? "/waste-companies/:id()/users/:user_id()/edit",
    meta: editSuzEOClPlvMeta || {},
    alias: editSuzEOClPlvMeta?.alias || [],
    redirect: editSuzEOClPlvMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-companies/[id]/users/[user_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createCrYcFkNnGXMeta?.name ?? "waste-companies-id-users-create",
    path: createCrYcFkNnGXMeta?.path ?? "/waste-companies/:id()/users/create",
    meta: createCrYcFkNnGXMeta || {},
    alias: createCrYcFkNnGXMeta?.alias || [],
    redirect: createCrYcFkNnGXMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-companies/[id]/users/create.vue").then(m => m.default || m)
  },
  {
    name: createIdrulUuc7eMeta?.name ?? "waste-companies-create",
    path: createIdrulUuc7eMeta?.path ?? "/waste-companies/create",
    meta: createIdrulUuc7eMeta || {},
    alias: createIdrulUuc7eMeta?.alias || [],
    redirect: createIdrulUuc7eMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-companies/create.vue").then(m => m.default || m)
  },
  {
    name: indexGgk2pZscR9Meta?.name ?? "waste-companies",
    path: indexGgk2pZscR9Meta?.path ?? "/waste-companies",
    meta: indexGgk2pZscR9Meta || {},
    alias: indexGgk2pZscR9Meta?.alias || [],
    redirect: indexGgk2pZscR9Meta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-companies/index.vue").then(m => m.default || m)
  },
  {
    name: edit3JY4BBYKfyMeta?.name ?? "waste-company-resources-cars-id-edit",
    path: edit3JY4BBYKfyMeta?.path ?? "/waste-company-resources/cars/:id()/edit",
    meta: edit3JY4BBYKfyMeta || {},
    alias: edit3JY4BBYKfyMeta?.alias || [],
    redirect: edit3JY4BBYKfyMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/cars/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexIFP7W5YVpZMeta?.name ?? "waste-company-resources-cars-id",
    path: indexIFP7W5YVpZMeta?.path ?? "/waste-company-resources/cars/:id()",
    meta: indexIFP7W5YVpZMeta || {},
    alias: indexIFP7W5YVpZMeta?.alias || [],
    redirect: indexIFP7W5YVpZMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/cars/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createRkgLmnl32dMeta?.name ?? "waste-company-resources-cars-create",
    path: createRkgLmnl32dMeta?.path ?? "/waste-company-resources/cars/create",
    meta: createRkgLmnl32dMeta || {},
    alias: createRkgLmnl32dMeta?.alias || [],
    redirect: createRkgLmnl32dMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/cars/create.vue").then(m => m.default || m)
  },
  {
    name: indexnb4g0nJGzjMeta?.name ?? "waste-company-resources-cars",
    path: indexnb4g0nJGzjMeta?.path ?? "/waste-company-resources/cars",
    meta: indexnb4g0nJGzjMeta || {},
    alias: indexnb4g0nJGzjMeta?.alias || [],
    redirect: indexnb4g0nJGzjMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/cars/index.vue").then(m => m.default || m)
  },
  {
    name: editoW8td2PloOMeta?.name ?? "waste-company-resources-devices-id-edit",
    path: editoW8td2PloOMeta?.path ?? "/waste-company-resources/devices/:id()/edit",
    meta: editoW8td2PloOMeta || {},
    alias: editoW8td2PloOMeta?.alias || [],
    redirect: editoW8td2PloOMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/devices/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexZrVFzV82saMeta?.name ?? "waste-company-resources-devices-id",
    path: indexZrVFzV82saMeta?.path ?? "/waste-company-resources/devices/:id()",
    meta: indexZrVFzV82saMeta || {},
    alias: indexZrVFzV82saMeta?.alias || [],
    redirect: indexZrVFzV82saMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/devices/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createcaTFP2oQyvMeta?.name ?? "waste-company-resources-devices-create",
    path: createcaTFP2oQyvMeta?.path ?? "/waste-company-resources/devices/create",
    meta: createcaTFP2oQyvMeta || {},
    alias: createcaTFP2oQyvMeta?.alias || [],
    redirect: createcaTFP2oQyvMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/devices/create.vue").then(m => m.default || m)
  },
  {
    name: indexfiYR6bHN3tMeta?.name ?? "waste-company-resources-devices",
    path: indexfiYR6bHN3tMeta?.path ?? "/waste-company-resources/devices",
    meta: indexfiYR6bHN3tMeta || {},
    alias: indexfiYR6bHN3tMeta?.alias || [],
    redirect: indexfiYR6bHN3tMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/devices/index.vue").then(m => m.default || m)
  },
  {
    name: editOJaUnJZR7qMeta?.name ?? "waste-company-resources-external-services-id-edit",
    path: editOJaUnJZR7qMeta?.path ?? "/waste-company-resources/external-services/:id()/edit",
    meta: editOJaUnJZR7qMeta || {},
    alias: editOJaUnJZR7qMeta?.alias || [],
    redirect: editOJaUnJZR7qMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/external-services/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: createMaOINI03OmMeta?.name ?? "waste-company-resources-external-services-create",
    path: createMaOINI03OmMeta?.path ?? "/waste-company-resources/external-services/create",
    meta: createMaOINI03OmMeta || {},
    alias: createMaOINI03OmMeta?.alias || [],
    redirect: createMaOINI03OmMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/external-services/create.vue").then(m => m.default || m)
  },
  {
    name: index2scMb3p5JMMeta?.name ?? "waste-company-resources-external-services",
    path: index2scMb3p5JMMeta?.path ?? "/waste-company-resources/external-services",
    meta: index2scMb3p5JMMeta || {},
    alias: index2scMb3p5JMMeta?.alias || [],
    redirect: index2scMb3p5JMMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/external-services/index.vue").then(m => m.default || m)
  },
  {
    name: editvpnEV5IvLPMeta?.name ?? "waste-company-resources-workers-id-edit",
    path: editvpnEV5IvLPMeta?.path ?? "/waste-company-resources/workers/:id()/edit",
    meta: editvpnEV5IvLPMeta || {},
    alias: editvpnEV5IvLPMeta?.alias || [],
    redirect: editvpnEV5IvLPMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/workers/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexrfO7swxPpDMeta?.name ?? "waste-company-resources-workers-id",
    path: indexrfO7swxPpDMeta?.path ?? "/waste-company-resources/workers/:id()",
    meta: indexrfO7swxPpDMeta || {},
    alias: indexrfO7swxPpDMeta?.alias || [],
    redirect: indexrfO7swxPpDMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/workers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createxZhnw8n2SbMeta?.name ?? "waste-company-resources-workers-create",
    path: createxZhnw8n2SbMeta?.path ?? "/waste-company-resources/workers/create",
    meta: createxZhnw8n2SbMeta || {},
    alias: createxZhnw8n2SbMeta?.alias || [],
    redirect: createxZhnw8n2SbMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/workers/create.vue").then(m => m.default || m)
  },
  {
    name: indexnVHn63WnOfMeta?.name ?? "waste-company-resources-workers",
    path: indexnVHn63WnOfMeta?.path ?? "/waste-company-resources/workers",
    meta: indexnVHn63WnOfMeta || {},
    alias: indexnVHn63WnOfMeta?.alias || [],
    redirect: indexnVHn63WnOfMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-company-resources/workers/index.vue").then(m => m.default || m)
  },
  {
    name: editEbNGPWYxyGMeta?.name ?? "waste-customers-id-edit",
    path: editEbNGPWYxyGMeta?.path ?? "/waste-customers/:id()/edit",
    meta: editEbNGPWYxyGMeta || {},
    alias: editEbNGPWYxyGMeta?.alias || [],
    redirect: editEbNGPWYxyGMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index1RS8uJMzKPMeta?.name ?? "waste-customers-id",
    path: index1RS8uJMzKPMeta?.path ?? "/waste-customers/:id()",
    meta: index1RS8uJMzKPMeta || {},
    alias: index1RS8uJMzKPMeta?.alias || [],
    redirect: index1RS8uJMzKPMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: edit0hdKME44ecMeta?.name ?? "waste-customers-id-subjects-subject_id-edit",
    path: edit0hdKME44ecMeta?.path ?? "/waste-customers/:id()/subjects/:subject_id()/edit",
    meta: edit0hdKME44ecMeta || {},
    alias: edit0hdKME44ecMeta?.alias || [],
    redirect: edit0hdKME44ecMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/[id]/subjects/[subject_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexCjX3iDzjRpMeta?.name ?? "waste-customers-id-subjects-subject_id",
    path: indexCjX3iDzjRpMeta?.path ?? "/waste-customers/:id()/subjects/:subject_id()",
    meta: indexCjX3iDzjRpMeta || {},
    alias: indexCjX3iDzjRpMeta?.alias || [],
    redirect: indexCjX3iDzjRpMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/[id]/subjects/[subject_id]/index.vue").then(m => m.default || m)
  },
  {
    name: createph9I3Ht6uZMeta?.name ?? "waste-customers-id-subjects-create",
    path: createph9I3Ht6uZMeta?.path ?? "/waste-customers/:id()/subjects/create",
    meta: createph9I3Ht6uZMeta || {},
    alias: createph9I3Ht6uZMeta?.alias || [],
    redirect: createph9I3Ht6uZMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/[id]/subjects/create.vue").then(m => m.default || m)
  },
  {
    name: editkmPBhkhfYeMeta?.name ?? "waste-customers-id-waste-customer-contracts-contract_id-edit",
    path: editkmPBhkhfYeMeta?.path ?? "/waste-customers/:id()/waste-customer-contracts/:contract_id()/edit",
    meta: editkmPBhkhfYeMeta || {},
    alias: editkmPBhkhfYeMeta?.alias || [],
    redirect: editkmPBhkhfYeMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/[id]/waste-customer-contracts/[contract_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: create6ZS1EqHKnvMeta?.name ?? "waste-customers-id-waste-customer-contracts-create",
    path: create6ZS1EqHKnvMeta?.path ?? "/waste-customers/:id()/waste-customer-contracts/create",
    meta: create6ZS1EqHKnvMeta || {},
    alias: create6ZS1EqHKnvMeta?.alias || [],
    redirect: create6ZS1EqHKnvMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/[id]/waste-customer-contracts/create.vue").then(m => m.default || m)
  },
  {
    name: createuAlcYc2RWRMeta?.name ?? "waste-customers-create",
    path: createuAlcYc2RWRMeta?.path ?? "/waste-customers/create",
    meta: createuAlcYc2RWRMeta || {},
    alias: createuAlcYc2RWRMeta?.alias || [],
    redirect: createuAlcYc2RWRMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/create.vue").then(m => m.default || m)
  },
  {
    name: exportjMwpNGWI7SMeta?.name ?? "waste-customers-export",
    path: exportjMwpNGWI7SMeta?.path ?? "/waste-customers/export",
    meta: exportjMwpNGWI7SMeta || {},
    alias: exportjMwpNGWI7SMeta?.alias || [],
    redirect: exportjMwpNGWI7SMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/export.vue").then(m => m.default || m)
  },
  {
    name: importF1VPMM6XQvMeta?.name ?? "waste-customers-import",
    path: importF1VPMM6XQvMeta?.path ?? "/waste-customers/import",
    meta: importF1VPMM6XQvMeta || {},
    alias: importF1VPMM6XQvMeta?.alias || [],
    redirect: importF1VPMM6XQvMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/import.vue").then(m => m.default || m)
  },
  {
    name: indexGHtJEMOhiGMeta?.name ?? "waste-customers",
    path: indexGHtJEMOhiGMeta?.path ?? "/waste-customers",
    meta: indexGHtJEMOhiGMeta || {},
    alias: indexGHtJEMOhiGMeta?.alias || [],
    redirect: indexGHtJEMOhiGMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-customers/index.vue").then(m => m.default || m)
  },
  {
    name: edit6t7aHxig6WMeta?.name ?? "waste-evidence-headers-id-edit",
    path: edit6t7aHxig6WMeta?.path ?? "/waste-evidence-headers/:id()/edit",
    meta: edit6t7aHxig6WMeta || {},
    alias: edit6t7aHxig6WMeta?.alias || [],
    redirect: edit6t7aHxig6WMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-evidence-headers/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexvXfFBpRxVxMeta?.name ?? "waste-evidence-headers-id",
    path: indexvXfFBpRxVxMeta?.path ?? "/waste-evidence-headers/:id()",
    meta: indexvXfFBpRxVxMeta || {},
    alias: indexvXfFBpRxVxMeta?.alias || [],
    redirect: indexvXfFBpRxVxMeta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-evidence-headers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createPonLRZ3iR5Meta?.name ?? "waste-evidence-headers-create",
    path: createPonLRZ3iR5Meta?.path ?? "/waste-evidence-headers/create",
    meta: createPonLRZ3iR5Meta || {},
    alias: createPonLRZ3iR5Meta?.alias || [],
    redirect: createPonLRZ3iR5Meta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-evidence-headers/create.vue").then(m => m.default || m)
  },
  {
    name: indexeygS8Yo372Meta?.name ?? "waste-evidence-headers",
    path: indexeygS8Yo372Meta?.path ?? "/waste-evidence-headers",
    meta: indexeygS8Yo372Meta || {},
    alias: indexeygS8Yo372Meta?.alias || [],
    redirect: indexeygS8Yo372Meta?.redirect,
    component: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/pages/waste-evidence-headers/index.vue").then(m => m.default || m)
  }
]