import { defineNuxtPlugin } from '#app'
import ExternalServiceImageGx from '@/assets/images/external-services/gx.png'
import ExternalServiceImageRadium from '@/assets/images/external-services/radium.png'
import ExternalServiceImageElte from '@/assets/images/external-services/elte.png'

export default defineNuxtPlugin(nuxtApp => {
  const { $store, $router, $_ } = nuxtApp

  const $this = {
    roles() {
      return {
        ADMIN: 'admin JRK',
        ADMIN_READ_ONLY: 'admin JRK jen pro čtení',
        MANAGER: 'správce TS',
        OPERATOR: 'operátor TS',
        ACCOUNTANT: 'účetní TS',
        DRIVER: 'řidič TS',
      }
    },
    adminRoles() {
      return [$this.roles().ADMIN, $this.roles().ADMIN_READ_ONLY]
    },
    rolesWithEmailUserRegistration() {
      return [
        $this.roles().ADMIN,
        $this.roles().ADMIN_READ_ONLY,
        $this.roles().MANAGER,
        $this.roles().OPERATOR,
        $this.roles().ACCOUNTANT,
      ]
    },
    formatWasteQuantityTons(kilograms, withUnit = true) {
      if (kilograms === null || typeof kilograms === 'undefined') return ''
      return $this.formatToDecimalPlaces($this.kilogramsToTons(kilograms)) + (withUnit ? ' t' : '')
    },
    formatWasteQuantity(kilograms, withUnit = true) {
      if (kilograms === null || typeof kilograms === 'undefined') kilograms = 0
      return $this.formatToDecimalPlaces(kilograms) + (withUnit ? ' kg' : '')
    },
    formatBinCapacity(litres, withUnit = true, unit = 'l') {
      if (litres === null || typeof litres === 'undefined') litres = 0
      return $this.formatToDecimalPlaces(litres) + (withUnit ? ` ${unit}` : '')
    },
    formatCollectionTime(seconds) {
      if (seconds === null || typeof seconds === 'undefined' || seconds < 0) seconds = 0
      const zeroPad = num => String(num).padStart(2, '0')

      seconds = Number(seconds)
      const sign = Math.sign(seconds)
      if (seconds < 0) seconds = ~seconds + 1

      const h = Math.floor(seconds / 3600)
      let m = Math.floor((seconds % 3600) / 60)
      m += Math.floor((((seconds % 3600) % 60) % 60) / 30) // round up seconds to minutes

      return (sign < 0 ? '-' : '') + h + ':' + zeroPad(m)
    },
    formatTrackLength(meters, withUnit = true) {
      if (meters === null || typeof meters === 'undefined') meters = 0
      return $this.formatToDecimalPlaces(meters / 1000) + (withUnit ? ' km' : '')
    },
    formatToDecimalPlaces(number, places = 1, replaceDecimalPoint = true) {
      number = +(Math.round(number + ('e+' + places)) + ('e-' + places))
      if (replaceDecimalPoint) return number.toString().replace('.', ',')
      return number.toString()
    },
    formatNumberToDecimalPlaces(number, places = 1) {
      return +(Math.round(number + ('e+' + places)) + ('e-' + places))
    },
    timestampToDate(seconds) {
      return seconds ? new Date(Number(seconds) * 1000).toLocaleDateString('cs-CZ') : ''
    },
    /**
     * @param date ISO date string
     * @returns {string}
     */
    formatDate(date) {
      if (date && date.toString().slice(-1) !== 'Z') date += 'Z'
      return date ? new Date(date).toLocaleDateString('cs-CZ') : ''
    },
    formatDateTime(ISOString) {
      if (ISOString && ISOString.toString().slice(-1) !== 'Z') ISOString += 'Z'
      return ISOString ? new Date(ISOString).toLocaleString('cs-CZ') : ''
    },
    getFilterDate(dateFrom, dateTo, withTime = false) {
      const outputDateFrom = withTime
        ? `${$this.formatDate(dateFrom)} ${$this.formatTime(dateFrom)}`
        : $this.formatDate(dateFrom)
      const outputDateTo = withTime
        ? `${$this.formatDate(dateTo)} ${$this.formatTime(dateTo)}`
        : $this.formatDate(dateTo)
      if (dateFrom && dateTo) {
        return `${outputDateFrom} - ${outputDateTo}`
      } else if (dateFrom) return `${$_('plugins/project.date_from', 'od')} ${outputDateFrom}`
      else return `${$_('plugins/project.date_to', 'do')} ${outputDateTo}`
    },
    getFirstDayOfCurrentWeek() {
      const now = new Date()

      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 2)
      date.setUTCHours(0, 0, 0)
      return date
    },
    getLastDayOfCurrentWeek() {
      const now = new Date()

      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (8 - now.getDay()))
      date.setUTCHours(20, 0, 0)
      return date
    },
    getFirstDayOfCurrentMonth() {
      const now = new Date()

      const date = new Date(now.getFullYear(), now.getMonth(), 2)
      date.setUTCHours(0, 0, 0)
      return date
    },
    getFirstDayOfLastQuarter() {
      const today = new Date()
      const currentMonth = today.getMonth() + 1 // Months are zero-indexed

      let lastQuarterMonth = currentMonth - ((currentMonth - 1) % 3) - 3
      let lastQuarterYear = today.getFullYear()

      if (lastQuarterMonth <= 0) {
        lastQuarterMonth += 12
        lastQuarterYear--
      }

      return new Date(lastQuarterYear, lastQuarterMonth - 1, 1)
    },
    getLastDayOfLastQuarter() {
      const today = new Date()
      const currentMonth = today.getMonth() + 1 // Months are zero-indexed

      let lastQuarterMonth = currentMonth - ((currentMonth - 1) % 3) - 1
      let lastQuarterYear = today.getFullYear()

      if (lastQuarterMonth <= 0) {
        lastQuarterMonth += 12
        lastQuarterYear--
      }

      return new Date(lastQuarterYear, lastQuarterMonth, 0)
    },
    getLastDayOfCurrentMonth() {
      const now = new Date()
      const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)

      const date = new Date(nextMonth - 1)
      date.setUTCHours(20, 0, 0)
      return date
    },
    getFirstDayOfCurrentYear() {
      const now = new Date()

      const date = new Date(now.getFullYear(), 0, 2)
      date.setUTCHours(0, 0, 0)
      return date
    },
    getLastDayOfCurrentYear() {
      const now = new Date()

      const date = new Date(now.getFullYear(), 11, 32) // 11 represents December (0-indexed month)
      date.setUTCHours(20, 0, 0)
      return date
    },
    getFirstDayOfLastYear() {
      const now = new Date()

      const date = new Date(now.getFullYear() - 1, 0, 2)
      date.setUTCHours(0, 0, 0)
      return date
    },
    getLastDayOfLastYear() {
      const now = new Date()
      const lastYear = now.getFullYear() - 1

      const date = new Date(lastYear, 11, 32) // Month is 0-based, so 11 corresponds to December
      date.setUTCHours(20, 0, 0)
      return date
    },
    getFirstDayTwoYearsAgo() {
      const now = new Date()

      const date = new Date(now.getFullYear() - 2, 0, 2)
      date.setUTCHours(0, 0, 0)
      return date
    },
    getLastDayTwoYearsAgo() {
      const now = new Date()
      const lastYear = now.getFullYear() - 2

      const date = new Date(lastYear, 11, 32) // Month is 0-based, so 11 corresponds to December
      date.setUTCHours(20, 0, 0)
      return date
    },
    getFirstDayThreeYearsAgo() {
      const now = new Date()

      const date = new Date(now.getFullYear() - 3, 0, 2)
      date.setUTCHours(0, 0, 0)
      return date
    },
    getLastDayThreeYearsAgo() {
      const now = new Date()
      const lastYear = now.getFullYear() - 3

      const date = new Date(lastYear, 11, 32) // Month is 0-based, so 11 corresponds to December
      date.setUTCHours(20, 0, 0)
      return date
    },
    getFirstDayOfLastWeek() {
      const now = new Date(new Date().toDateString())
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset()) // add timezone offset
      const first = now.getDate() - now.getDay() - 6

      const date = new Date(now.setDate(first))
      date.setUTCHours(0, 0, 0)
      return date
    },
    getLastDayOfLastWeek() {
      const now = new Date(new Date().toDateString())
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset()) // add timezone offset
      const last = now.getDate() - now.getDay()

      const date = new Date(now.setDate(last))
      date.setUTCHours(20, 0, 0)
      return date
    },
    getFirstDayOfLastMonth() {
      const now = new Date(new Date().toDateString())
      const first = new Date(now.getFullYear(), now.getMonth() - 1, 1)
      first.setMinutes(first.getMinutes() - first.getTimezoneOffset()) // add timezone offset
      first.setUTCHours(0, 0, 0)

      return first
    },
    getLastDayOfLastMonth() {
      const now = new Date(new Date().toDateString())
      const last = new Date(now.getFullYear(), now.getMonth(), 0)
      last.setMinutes(last.getMinutes() - last.getTimezoneOffset()) // add timezone offset
      last.setUTCHours(20, 0, 0)

      return last
    },
    formatPhoneNumber(phoneNumber) {
      if (phoneNumber === null || typeof phoneNumber === 'undefined') return
      phoneNumber = phoneNumber.replace(/\s/g, '')
      // phone number with prefix
      if (phoneNumber[0] === '+')
        return (
          '+' +
          phoneNumber
            .substr(1)
            .match(/.{1,3}/g)
            .join(' ')
        )
      // Czech phone number
      if (phoneNumber.length === 9) return phoneNumber.match(/.{1,3}/g).join(' ')
      // Slovak phone number
      if (phoneNumber.length === 10)
        return [
          phoneNumber.substr(0, 4),
          phoneNumber
            .substr(4)
            .match(/.{1,3}/g)
            .join(' '),
        ].join(' ')

      return phoneNumber
    },
    formatZipCode(zipCode) {
      if (zipCode)
        return zipCode
          .replace(/\s/g, '')
          .match(/.{1,3}/g)
          .join(' ')
    },
    formatTime(ISOString) {
      if (ISOString && ISOString.toString().slice(-1) !== 'Z') ISOString += 'Z'
      return ISOString
        ? new Date(ISOString).toLocaleTimeString('cs-CZ', {
            hour: '2-digit',
            minute: '2-digit',
          })
        : ''
    },
    getPercent(lastOrAverage, expected) {
      return expected ? $this.formatToDecimalPlaces((lastOrAverage / expected) * 100 || 0, 0) : 100
    },
    formatPrice(price, locale = 'cs-CZ', currency = 'CZK') {
      if (price === null || typeof price === 'undefined') price = 0
      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
      })

      return formatter.format(price)
    },
    /**
     * Converts time string in 'HH:MM' format to seconds
     * @param time
     * @returns {*}
     */
    convertTimeToSeconds(time) {
      const parsedTime = time.split(':')
      return (Number(parsedTime[0]) * 60 + Number(parsedTime[1])) * 60
    },
    formatDuration(seconds, withUnit = true) {
      return $this.formatToDecimalPlaces(seconds / 3600, 0) + (withUnit ? ' h' : '')
    },
    /**
     * return true if text should be dark for given background color
     * @param color string in hex format
     * @returns {boolean}
     */
    isDarkText(color) {
      if (color === null || typeof color === 'undefined') return false
      const c = color.substring(1) // strip #
      const rgb = parseInt(c, 16) // convert rrggbb to decimal
      const r = (rgb >> 16) & 0xff // extract red
      const g = (rgb >> 8) & 0xff // extract green
      const b = (rgb >> 0) & 0xff // extract blue

      const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

      return luma > 125
    },
    kilogramsToTons(kg) {
      return kg / 1000
    },
    tonsToKilograms(t) {
      return t * 1000
    },
    metersToKilometers(m) {
      return m / 1000
    },
    kilometersToMeters(km) {
      return km * 1000
    },
    exportFile(dispatchType, payload, filename = null) {
      return new Promise((resolve, reject) => {
        $store
          .dispatch(dispatchType, payload)
          .then(r => {
            const data = r.data ? r.data : r

            if (!filename) {
              if (r.headers && r.headers['content-disposition']) {
                // Split the header into parts
                const parts = r.headers['content-disposition'].split(';')

                // Find the part containing the filename
                const filenamePart = parts.find(part => part.trim().startsWith('filename'))

                if (!filenamePart) {
                  filename = 'file'
                } else {
                  // Extract the filename value
                  const filenameValue = filenamePart.split('=')[1].trim()

                  // Check if there is a UTF-8 filename
                  const utf8FilenamePart = parts.find(part => part.trim().startsWith('filename*=UTF-8'))

                  if (utf8FilenamePart) {
                    // Extract the UTF-8 filename value
                    const utf8FilenameValue = utf8FilenamePart.split('=UTF-8')[1].trim()

                    // Decode the UTF-8 filename
                    const decodedFilename = decodeURIComponent(utf8FilenameValue)

                    filename = decodedFilename.replaceAll('"', '').replaceAll("'", '')
                  } else {
                    filename = filenameValue.replaceAll('"', '')
                  }
                }
              } else filename = 'file'
            } else {
              // set correct file extension
              if (data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') filename += '.xlsx'
              else if (data.type === 'application/vnd.ms-excel') filename += '.xls'
              else if (data.type === 'application/pdf') filename += '.pdf'
              else if (data.type === 'text/csv') filename += '.csv'
            }

            const a = document.body.appendChild(document.createElement('a'))
            a.style.display = 'none'
            const blob = new Blob([data], { type: data.type })
            a.download = filename
            a.href = URL.createObjectURL(blob)
            a.click()
            setTimeout(function () {
              URL.revokeObjectURL(a.href)
            }, 100) // cleanup
            a.parentNode.removeChild(a)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    downloadAttachment(dispatchType, file) {
      return new Promise((resolve, reject) => {
        $store
          .dispatch(dispatchType, file.id)
          .then(r => {
            const a = document.body.appendChild(document.createElement('a'))
            a.style.display = 'none'
            const options = {}
            if (file.contentType) options.type = file.contentType
            const blob = new Blob([r], options)
            a.download = file.name
            a.href = URL.createObjectURL(blob)
            a.click()
            setTimeout(function () {
              URL.revokeObjectURL(a.href)
            }, 100) // cleanup
            a.parentNode.removeChild(a)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    millisecondsToMinutes(ms) {
      return ms / 60000
    },
    millisecondsToDays(ms) {
      return ms / (60000 * 60 * 24)
    },
    drawLinesIntoMap(map, waypointItems, wasteCollectionHeadersItems) {
      // Not exact line - old way
      if (waypointItems.length === 0) {
        if (wasteCollectionHeadersItems.length > 1) {
          $this.drawSimpleLineIntoMap(map, wasteCollectionHeadersItems)
        }
        return
      }

      waypointItems = waypointItems.sort((a, b) => {
        const dateA: Date = new Date(a.recordedTs)
        const dateB: Date = new Date(b.recordedTs)
        return dateA - dateB
      })

      wasteCollectionHeadersItems = wasteCollectionHeadersItems.sort((a, b) => {
        const dateA: Date = new Date(a.processedTs)
        const dateB: Date = new Date(b.processedTs)
        return dateA - dateB
      })

      let result = []

      // If there are any waypointItems before the first wasteCollectionHeadersItem, we need to add them to the result
      const waypointItemsBeforeFirstWasteCollectionHeadersItem = waypointItems.filter(
        data =>
          new Date(data.recordedTs) <=
          (wasteCollectionHeadersItems[0]?.processedTs
            ? new Date(wasteCollectionHeadersItems[0]?.processedTs)
            : new Date())
      )

      result.push({
        waypointItems: waypointItemsBeforeFirstWasteCollectionHeadersItem,
        timeDiff: null,
      })

      for (const event of wasteCollectionHeadersItems) {
        // const prevEvent = wasteCollectionHeadersItems[wasteCollectionHeadersItems.indexOf(event) - 1]
        const nextEvent = wasteCollectionHeadersItems[wasteCollectionHeadersItems.indexOf(event) + 1]

        if (!nextEvent) {
          const filteredWaypointItems = waypointItems.filter(
            data => new Date(data.recordedTs) >= new Date(event.processedTs)
          )

          const lastWaypointItem =
            result[result.length - 1]?.waypointItems[result[result.length - 1]?.waypointItems.length - 1]
          if (lastWaypointItem) {
            filteredWaypointItems.unshift(lastWaypointItem)
          }

          result.push({
            waypointItems: filteredWaypointItems,
            timeDiff: null,
          })
          break
        }

        const startTime = event.processedTs
        const endTime = nextEvent ? nextEvent.processedTs : event.processedTs

        const timeDiff = Math.floor((new Date(endTime) - new Date(startTime)) / 1000)

        const filteredWaypointItems = waypointItems.filter(
          data => new Date(data.recordedTs) >= new Date(startTime) && new Date(data.recordedTs) <= new Date(endTime)
        )

        const lastWaypointItem =
          result[result.length - 1]?.waypointItems[result[result.length - 1]?.waypointItems.length - 1]
        if (lastWaypointItem) {
          filteredWaypointItems.unshift(lastWaypointItem)
        }

        result.push({
          waypointItems: filteredWaypointItems,
          timeDiff,
        })
      }

      if (result.length > 1) {
        result = JSON.parse(JSON.stringify(result))

        for (let i = 0; i < result.length; i++) {
          let greenLineCoordinates = []
          let redLineCoordinates = []
          let yellowLineCoordinates = []
          let grayLineCoordinates = []

          if (result[i].timeDiff === null) {
            grayLineCoordinates = [
              ...grayLineCoordinates,
              ...result[i].waypointItems.map(item => {
                return {
                  lat: item.location.latitude,
                  lng: item.location.longitude,
                }
              }),
            ]
          } else if (result[i].timeDiff <= 2 * 60) {
            greenLineCoordinates = [
              ...greenLineCoordinates,
              ...result[i].waypointItems.map(item => {
                return {
                  lat: item.location.latitude,
                  lng: item.location.longitude,
                }
              }),
            ]
          } else if (result[i].timeDiff > 2 * 60 && result[i].timeDiff <= 5 * 60) {
            yellowLineCoordinates = [
              ...yellowLineCoordinates,
              ...result[i].waypointItems.map(item => {
                return {
                  lat: item.location.latitude,
                  lng: item.location.longitude,
                }
              }),
            ]
          } else if (result[i].timeDiff > 5) {
            redLineCoordinates = [
              ...redLineCoordinates,
              ...result[i].waypointItems.map(item => {
                return {
                  lat: item.location.latitude,
                  lng: item.location.longitude,
                }
              }),
            ]
          }

          ;[greenLineCoordinates, yellowLineCoordinates, redLineCoordinates, grayLineCoordinates].forEach(
            (line, index) => {
              const colors = {
                0: '#b0c85c',
                1: '#e7c767',
                2: '#dd7676',
                3: '#c5c5c5',
              }

              if (line.length > 1) {
                const path = new google.maps.Polyline({
                  path: line,
                  strokeColor: colors[index],
                  strokeOpacity: 1,
                  strokeWeight: 7,
                  zIndex: 1,
                })
                const pathBorder = new google.maps.Polyline({
                  path: line,
                  strokeColor: colors[index],
                  strokeOpacity: 1,
                  strokeWeight: 10,
                })
                path.setMap(map)
                pathBorder.setMap(map)
              }
            }
          )
        }
      }
    },
    drawSimpleLineIntoMap(map, wasteCollectionHeadersItems) {
      function calculateBasicLine(
        i,
        greenLineCoordinates,
        yellowLineCoordinates,
        redLineCoordinates,
        lastItem = false
      ) {
        if (wasteCollectionHeadersItems[i].location && wasteCollectionHeadersItems[i + 1].location) {
          const timeDiff = $this.millisecondsToMinutes(
            new Date(wasteCollectionHeadersItems[i + 1].processedTs) -
              new Date(wasteCollectionHeadersItems[i].processedTs)
          )
          const location = lastItem
            ? wasteCollectionHeadersItems[i + 1].location
            : wasteCollectionHeadersItems[i].location
          if (timeDiff >= 0) {
            if (timeDiff <= 2)
              greenLineCoordinates.push({
                lat: location.latitude,
                lng: location.longitude,
              })
            else if (timeDiff <= 5)
              yellowLineCoordinates.push({
                lat: location.latitude,
                lng: location.longitude,
              })
            else
              redLineCoordinates.push({
                lat: location.latitude,
                lng: location.longitude,
              })
          }
        }
      }

      wasteCollectionHeadersItems = JSON.parse(JSON.stringify(wasteCollectionHeadersItems))
      wasteCollectionHeadersItems.sort((a, b) => new Date(a.processedTs) - new Date(b.processedTs)) // sort by time ascending
      const greenLineCoordinates = []
      const redLineCoordinates = []
      const yellowLineCoordinates = []
      for (let i = 0; i < wasteCollectionHeadersItems.length; i++) {
        if (i < wasteCollectionHeadersItems.length - 1) {
          // skip last item
          calculateBasicLine(i, greenLineCoordinates, yellowLineCoordinates, redLineCoordinates)
        }
      }
      calculateBasicLine(
        wasteCollectionHeadersItems.length - 2,
        greenLineCoordinates,
        yellowLineCoordinates,
        redLineCoordinates,
        true
      )

      if (greenLineCoordinates.length > 1) {
        const greenPath = new google.maps.Polyline({
          path: greenLineCoordinates,
          strokeColor: '#b0c85c',
          strokeOpacity: 1,
          strokeWeight: 7,
          zIndex: 1,
        })
        const greenPathBorder = new google.maps.Polyline({
          path: greenLineCoordinates,
          strokeColor: '#8caf13',
          strokeOpacity: 1,
          strokeWeight: 10,
        })
        greenPath.setMap(map)
        greenPathBorder.setMap(map)
      }

      if (yellowLineCoordinates.length > 1) {
        const yellowPath = new google.maps.Polyline({
          path: yellowLineCoordinates,
          strokeColor: '#e7c767',
          strokeOpacity: 1,
          strokeWeight: 7,
          zIndex: 1,
        })
        const yellowPathBorder = new google.maps.Polyline({
          path: yellowLineCoordinates,
          strokeColor: '#d9ac25',
          strokeOpacity: 1,
          strokeWeight: 10,
        })
        yellowPathBorder.setMap(map)
        yellowPath.setMap(map)
      }

      if (redLineCoordinates.length > 1) {
        const redPath = new google.maps.Polyline({
          path: redLineCoordinates,
          strokeColor: '#dd7676',
          strokeOpacity: 1,
          strokeWeight: 7,
          zIndex: 1,
        })
        const redPathBorder = new google.maps.Polyline({
          path: redLineCoordinates,
          strokeColor: '#d84c4c',
          strokeOpacity: 1,
          strokeWeight: 10,
        })
        redPathBorder.setMap(map)
        redPath.setMap(map)
      }
    },

    // isUserAllowed(permission = 'r', route = null, allowAdminRoles = true) {
    //   // if route is not specified, use current route path
    //   if (!route) {
    //     let currentRoute = $router.history.current.path.split('/')
    //     if (currentRoute.length >= 2) route = currentRoute[1]
    //     else return false
    //   }
    //   // if api response does not specify user's role, user is not allowed
    //   if (!$store.getters['users/getUserInfo'] || !$store.getters['users/getUserInfo'].role) return false
    //   let role = $store.getters['users/getUserInfo'].role
    //   // admin role is always allowed; regional manager is always allowed but some action are disabled (per page)
    //   if (allowAdminRoles && (role === 'admin JRK' || role === 'jrk regionální manažer')) return true
    //   // if role's permissions are not specified, user is not allowed
    //   if (!$store.getters['global/getPermissions'][role]) return false
    //   let permissions = $store.getters['global/getPermissions'][role][route]
    //
    //   return permissions ? permissions.includes(permission) : false
    // },
    getRoutePath(path = null) {
      let routePath
      if (path === null)
        routePath = $router.currentRoute.value.path.replaceAll(new RegExp(/\/\d+/, 'g'), '') // removes IDs from path
      else routePath = path.replaceAll(new RegExp(/\/\d+/, 'g'), '')

      routePath = routePath.substring(1, routePath.length)
      routePath = routePath.replaceAll('/edit', '')
      routePath = routePath.replaceAll('/create', '')
      routePath = routePath.replaceAll('/import', '')
      routePath = routePath.replaceAll('/export', '')

      return routePath
    },
    getAction(route) {
      const routesToActions = {
        'waste-companies': 'WasteCompany',
        'administration/devices': 'AllDevices',
        'administration/external-services': 'AllDevices',
        'waste-company-resources/devices': 'Device',
        'waste-company-resources/external-services': 'Device',
        'waste-company-resources/cars': 'Car',
        'administration/users': 'AllWasteCompanyUsers',
        'waste-collection-routes/regular/null/waste-collections': 'WasteCollection',
        'waste-collection-routes/exceptional/waste-collections': 'WasteCollection',
        'waste-collection-routes/regular': 'WasteCollectionRoute',
        'waste-collection-routes/exceptional': 'WasteCollectionRoute',
        'waste-collection-routes/calendar': 'WasteCollectionRoute',
        'waste-customers': 'WasteCompanyCustomer',
        'waste-customers/waste-customer-contracts': 'WasteCustomerContract',
        'waste-customers/subjects': 'Subject',
        'waste-evidence-headers': 'WasteEvidenceHeader',
        'waste-company-resources/workers': 'Worker',
        'waste-companies/users': 'WasteCompanyUser',
      }
      if (routesToActions[route]) route = routesToActions[route]

      return route
    },
    isUserAllowed(permission = null, route = null, action = null) {
      if (route === '' || route === 'login' || route === 'dashboard' || route === 'profile') return true

      // if route is not specified, use current route path
      if (!route) {
        route = $this.getRoutePath()
        route = this.getAction(route)
      }

      // if action is not specified, get action by route and add permission
      if (action === null) {
        action = this.getAction(route)
        switch (permission) {
          case 's':
            action += '.aDetail'
            break
          case 'c':
            action += '.aAdd'
            break
          case 'u':
            action += '.aEdit'
            break
          case 'd':
            action += '.aRemove'
            break
        }
      } else {
        if (action !== '') action = `${route}.${action}`
        else action = route
      }

      return !!$store.getters['users/getUserPermissions']?.includes(action)
    },
    get403Error() {
      createError({
        statusCode: 403,
        message: $_('project/insufficient_permissions', 'Nedostatečná uživatelská oprávnění'),
      })
    },
    filterWasteEvidenceHeaders(id) {
      $store.dispatch('waste_evidence_headers/setRouteIdFilter', id)
      $router.push('/waste-evidence-headers')
    },
    /**
     * Removes validation message when input changes its value from empty
     * @param event
     * @param name
     */
    removeError(event, name) {
      let value = event
      if (event?.target) value = event?.target?.value
      if (value !== '' && $store.getters['validation/exists'](name)) {
        $store.dispatch('validation/clearError', name)
      }
    },
    /**
     * Transforms identification number for search select usage
     * @param params
     * @returns string|null
     */
    getIdentificationNumber(params) {
      let identificationNumber = null
      if (params.hasOwnProperty('search') || params.hasOwnProperty('selectedIds')) {
        if (params.search) identificationNumber = params.search
        else if (params.selectedIds && params.selectedIds.length) {
          identificationNumber = params.selectedIds[0]
        }
      }

      return identificationNumber
    },
    setCompanyData(companyData) {
      const company = {}
      company.identificationNumber = companyData.ico
      company.taxIdentificationNumber = companyData.dic
      company.name = companyData.companyName
      const number = `${companyData.streetHouseNumber}${
        companyData.streetOrientationNumber ? '/' + companyData.streetOrientationNumber : ''
      }`
      company.streetAddress = companyData.street ? `${companyData.street} ${number}` : number
      company.city = companyData.town || ''
      company.zipcode = companyData.zip || ''
      return company
    },
    formatStreetAddress(address) {
      const conscriptionNumber = address.conscriptionNumber
      const provisionalNumber = address.provisionalNumber
      const streetNumber = address.streetNumber
      let houseNumber = null

      if (conscriptionNumber) {
        if (streetNumber) {
          if (provisionalNumber) houseNumber = `${conscriptionNumber}/${streetNumber} ${provisionalNumber}`
          else houseNumber = `${conscriptionNumber}/${streetNumber}`
        } else houseNumber = conscriptionNumber
      } else if (streetNumber) houseNumber = streetNumber

      return houseNumber ? `${address.street ?? address.city ?? ''} ${houseNumber}` : address.street
    },
    /**
     * Converts decimal degrees to degrees minutes seconds.
     *
     * @param dd the decimal degrees value.
     * @param isLng specifies whether the decimal degrees value is a longitude.
     * @return degrees minutes seconds string in the format 49°15'51.35"N
     */
    convertToDms(dd, isLng) {
      const dir = dd < 0 ? (isLng ? 'W' : 'S') : isLng ? 'E' : 'N'

      const absDd = Math.abs(dd)
      const deg = absDd | 0
      const frac = absDd - deg
      const min = (frac * 60) | 0
      let sec = frac * 3600 - min * 60
      // Round it to 2 decimal points.
      sec = Math.round(sec * 100) / 100

      return deg + '°' + min + "'" + sec + '"' + dir
    },
    formatGps(latitude, longitude) {
      return `${this.convertToDms(latitude, false)}, ${this.convertToDms(longitude, true)}`
    },
    /**
     * Transforms object's keys to lowercase
     */
    lowercaseKeys(obj) {
      return Object.keys(obj).reduce((accumulator, key) => {
        accumulator[key.toLowerCase()] = obj[key]
        return accumulator
      }, {})
    },
    getExternalServiceIcon(serviceName: string) {
      switch (serviceName) {
        case 'GXSolutions':
          return ExternalServiceImageGx
        case 'Radium':
          return ExternalServiceImageRadium
        case 'ELTEGPSSepan':
          return ExternalServiceImageElte
        default:
          return null
      }
    },
  }

  return {
    provide: {
      project: $this,
    },
  }
})
