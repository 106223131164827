import { Auth, ExpiredAuthSessionError } from '#auth/runtime'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'
import { defu } from 'defu';

// Active schemes
import { OpenIDConnectScheme } from '#auth/runtime'

// Options
let options = {
    "globalMiddleware": false,
    "enableMiddleware": true,
    "resetOnError": false,
    "resetOnResponseError": false,
    "ignoreExceptions": false,
    "scopeKey": "scope",
    "rewriteRedirects": true,
    "fullPathRedirect": false,
    "redirectStrategy": "storage",
    "watchLoggedIn": true,
    "tokenValidationInterval": false,
    "redirect": {
        "login": "\u002Flogin",
        "logout": "\u002F",
        "home": "\u002F",
        "callback": "\u002Flogin"
    },
    "stores": {
        "state": {
            "namespace": "auth"
        },
        "pinia": {
            "enabled": false,
            "namespace": "auth"
        },
        "cookie": {
            "enabled": true,
            "prefix": "auth.",
            "options": {
                "path": "\u002F",
                "sameSite": "lax",
                "maxAge": 31536000
            },
            "secure": true
        },
        "local": {
            "enabled": false,
            "prefix": "auth."
        },
        "session": {
            "enabled": false,
            "prefix": "auth."
        }
    },
    "defaultStrategy": "oidc"
}

export default defineNuxtPlugin({
    name: 'nuxt-alt:auth',
    async setup(nuxtApp) {
        // Create a new Auth instance
        const auth = new Auth(nuxtApp, options)

        // Register strategies
        auth.registerStrategy('oidc', new OpenIDConnectScheme(auth, defu(useRuntimeConfig()?.public?.auth?.strategies?.['oidc'], {"name":"oidc","enabled":true,"clientId":"snadnee_frontend_js_code","endpoints":{"configuration":"https://auth.econit.chytreodpady.cz/.well-known/openid-configuration","authorization":"https://auth.econit.chytreodpady.cz/connect/authorize"},"idToken":{"property":"id_token","prefix":"_id_token.","expirationPrefix":"_id_token_expiration."},"refreshToken":{"property":"refresh_token"},"autoLogout":false,"responseType":"code","grantType":"authorization_code","scope":["openid","profile","email","api_technicke_sluzby.read","offline_access"],"codeChallengeMethod":"S256","redirectUri":"https://app-ts.jrkdigital.eu/login","logoutRedirectUri":"https://app-ts.jrkdigital.eu/login","ssr":false})))

        nuxtApp.provide('auth', auth)

        return auth.init()
        .catch(error => {
            if (process.client) {
                // Don't console log expired auth session errors. This error is common, and expected to happen.
                // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
                // token. We don't want to log this as an error.
                if (error instanceof ExpiredAuthSessionError) {
                    return
                }

                console.error('[ERROR] [AUTH]', error)
            }
        })
    }
})