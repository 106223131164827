const API_URL = '/export'

export const state = () => ({
  filter_options: {},
  export_data: [],
})

export const getters = {
  getFilterOptions: state => {
    return state.filter_options
  },
  getExportData: state => branch_id => {
    return state.export_data[branch_id] || {}
  },
}

export const mutations = {
  PUSH_OR_REPLACE_FILTER_OPTIONS(state, filterOptions) {
    state.filter_options = filterOptions
  },
  PUSH_OR_REPLACE_EXPORT_DATA(state, data) {
    state.export_data[data.wasteCompanyBranchId] = data
  },
}

export const actions = {
  async fetchFilterOptions({ commit }, waste_company_branch_id) {
    let options = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/waste-customers/filter-options`)

    commit('PUSH_OR_REPLACE_FILTER_OPTIONS', options)

    return options
  },
  async fetchWasteCustomersExportData({ commit }, { waste_company_branch_id, params }) {
    let data = await this.$axios.$post(`${API_URL}/${waste_company_branch_id}/waste-customers`, params)

    commit('PUSH_OR_REPLACE_EXPORT_DATA', data)

    return data
  },
  exportFile({ commit }, { waste_company_branch_id, params, exportFormat }) {
    let url = `${API_URL}/${waste_company_branch_id}/waste-customers/export`

    console.log('exportFormat', exportFormat)
    if (exportFormat) {
      url += `?exportFormat=${exportFormat}`
    }

    return this.$axios.post(url, params, {
      responseType: 'blob',
    })
  },
}
