import { useStorage } from '@vueuse/core'

const DEFAULT_PERMISSIONS = {
  // c = create, r = read, u = update, d = delete
  '': ['r'],
  login: ['r'],
}

export const state = () => ({
  waste_company_branch_id: useStorage('wasteCompanyBranchId', null),
  countryCode: useStorage('countryCode', null),
  countryCulture: useStorage('countryCulture', null),
  aresData: [],
  permissions: {
    'admin JRK': {
      ...DEFAULT_PERMISSIONS,
      dashboard: ['c', 'r', 'u', 'd'],
      'waste-companies': ['c', 'r', 'u', 'd'],
      'waste-company-users': ['c', 'r', 'u', 'd'],
      'waste-collection-routes': ['c', 'r', 'u', 'd'],
      'waste-company-resources': ['c', 'r', 'u', 'd'],
      'waste-evidence-headers': ['c', 'r', 'u', 'd'],
      'waste-customers': ['c', 'r', 'u', 'd'],
      administration: ['c', 'r', 'u', 'd'],
    },
    'admin JRK jen pro čtení': {
      ...DEFAULT_PERMISSIONS,
      dashboard: ['r'],
      'waste-companies': ['r'],
      'waste-company-users': ['r'],
      'waste-collection-routes': ['r'],
      'waste-company-resources': ['r'],
      'waste-evidence-headers': ['r'],
      'waste-customers': ['r'],
      administration: ['r'],
    },
    'účetní TS': {
      ...DEFAULT_PERMISSIONS,
      dashboard: ['c', 'r', 'u', 'd'],
      'waste-company-resources': ['c', 'r', 'u', 'd'],
      'waste-customers': ['c', 'r', 'u', 'd'],
    },
    'operátor TS': {
      ...DEFAULT_PERMISSIONS,
      dashboard: ['c', 'r', 'u', 'd'],
      'waste-collection-routes': ['c', 'r', 'u', 'd'],
      'waste-evidence-headers': ['c', 'r', 'u', 'd'],
    },
    'řidič TS': {
      ...DEFAULT_PERMISSIONS,
    },
    'správce TS': {
      ...DEFAULT_PERMISSIONS,
      dashboard: ['c', 'r', 'u', 'd'],
      'waste-companies': ['r', 'u'],
      'waste-company-users': ['c', 'r', 'u', 'd'],
      'waste-collection-routes': ['c', 'r', 'u', 'd'],
      'waste-company-resources': ['c', 'r', 'u', 'd'],
      'waste-evidence-headers': ['c', 'r', 'u', 'd'],
      'waste-customers': ['c', 'r', 'u', 'd'],
    },
    'jrk regionální manažer': {
      ...DEFAULT_PERMISSIONS,
      dashboard: ['r'],
      'waste-companies': ['r'],
      'waste-company-users': ['r'],
      'waste-collection-routes': ['r', 'd'],
      'waste-company-resources': ['r'],
      'waste-evidence-headers': ['r'],
      'waste-customers': ['r'],
      administration: ['r'],
    },
  },
})

export const getters = {
  getWasteCompanyBranchId: state => {
    return state.waste_company_branch_id
  },
  getAresData: state => {
    return state.aresData
  },
  getPermissions: state => {
    return state.permissions
  },
  getCountryCode: state => {
    return state.countryCode
  },
  getCountryCulture: state => {
    return state.countryCulture
  },
}

export const mutations = {
  SET_COMPANY_BRANCH_ID(state, waste_company_branch_id) {
    state.waste_company_branch_id = waste_company_branch_id
  },
  SET_ARES_DATA(state, company) {
    let existing = state.aresData.filter(obj => {
      return obj.identificationNumber === company.identificationNumber
    })
    if (!existing.length) state.aresData.push(company)
  },
  SET_COUNTRY_CODE(state, code) {
    state.countryCode = code
  },
  SET_COUNTRY_CULTURE(state, culture) {
    state.countryCulture = culture
  },
}

export const actions = {
  setWasteCompanyBranchId({ commit }, waste_company_branch_id) {
    commit('SET_COMPANY_BRANCH_ID', waste_company_branch_id)
  },
  async fetchAres({ commit, state }, params) {
    if (!Object.hasOwn(params, 'countryCode')) params.countryCode = state.countryCode

    if (params) {
      const identificationNumber = this.$project.getIdentificationNumber(params)
      // SK or default => CZ
      if (params.countryCode == null) {
        params.countryCode = 'CZ'
      }
      if (!['CZ', 'SK'].includes(params.countryCode)) return null
      const url = params.countryCode === 'SK' ? `/rpo/${identificationNumber}` : `/ares/${identificationNumber}`
      // look for existing ares data in state
      let existing = state.aresData.find(obj => obj.identificationNumber === identificationNumber)
      if (existing) return existing
      if (
        !isNaN(identificationNumber) &&
        this.$global.validateIdentificationNumber(identificationNumber, params.countryCode)
      ) {
        let ares = await this.$axios(url, {
          method: 'get',
          baseURL:
            useRuntimeConfig().public.APP_ENV === 'local' ? `${useRuntimeConfig().public.BACKEND_URL}/api` : '/api',
        }).catch(e => {
          return null
        })
        if (!ares) return null

        let company = this.$project.setCompanyData(ares.data.data)

        commit('SET_ARES_DATA', company)

        return company
      }
      return null
    }
    return null
  },
  async fetchAresMultiple({ commit, state }, identificationNumbers) {
    let validated = []
    let response = {}
    identificationNumbers.forEach(identificationNumber => {
      let existing = state.aresData.find(obj => obj.identificationNumber === identificationNumber)
      if (existing) {
        response[identificationNumber] = existing
        return
      }
      if (!isNaN(identificationNumber) && this.$global.validateIdentificationNumber(identificationNumber))
        validated.push(identificationNumber)
    })

    if (validated.length) {
      const url = state.countryCode === 'CZ' ? '/ares-multiple' : '/rpo-multiple'

      let ares = await this.$axios(url, {
        method: 'post',
        baseURL: process.env.APP_ENV === 'local' ? `${process.env.BACKEND_URL}/api` : '/api',
        data: { companies: validated },
      })

      let data = ares.data
      Object.keys(ares.data).forEach(key => {
        let companyData = data[key]
        let company = this.$project.setCompanyData(companyData)

        commit('SET_ARES_DATA', company)

        response[key] = company
      })
    }

    return response
  },
  setCountryCode({ commit }, value) {
    commit('SET_COUNTRY_CODE', value)
  },
  setCountryCulture({ commit }, value) {
    commit('SET_COUNTRY_CULTURE', value)
  },
}
