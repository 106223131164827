export const state = () => ({
  search_selects: [],
})

export const getters = {
  getAll: state => {
    return state.search_selects
  },
  get: state => name => {
    return state[name]['data']
  },
  getStatus: state => name => {
    if (typeof state[name] === 'undefined') {
      return null
    }

    return state[name]['meta']['status']
  },
}

export const mutations = {
  REPLACE_SEARCH_SELECT(state, { name, data }) {
    state[name]['data'] = {
      fetchedAt: new Date().getTime(),
      data,
    }
  },
  SET_LOADING_STATUS(state, name) {
    if (typeof state[name] === 'undefined') {
      state[name] = {
        meta: {
          status: 'loading',
        },
      }
    }

    state[name]['meta']['status'] = 'loading'
  },
  SET_DONE_STATUS(state, name) {
    state[name]['meta']['status'] = 'done'
  },
}

export const actions = {
  async fetch({ commit, state, getters }, params2) {
    let { name, ...params } = params2

    // When more search selects are on the same page (with same name)
    // Just wait for response form first request and use that instead of
    // triggering more of them.
    let search_select = null
    if (getters.getStatus(name) === 'loading') {
      // Should wait for "done" status (is set when first request is done)
      await checkForUpdate(getters, name)

      search_select = getters.get(name)
    } else {
      // Mark it as loading
      commit('SET_LOADING_STATUS', name)

      // Trigger first request
      search_select = await this.$axios.$get('search_selects/' + name, {
        params,
      })

      // Mark it as done
      commit('SET_DONE_STATUS', name)

      // Save data to store
      commit('REPLACE_SEARCH_SELECT', {
        name,
        data: search_select.data,
      })
    }

    return search_select.data

    // If there was fetched search select is last 30 seconds use it.
    // TODO it does not skips request triggered at the same time
    //  (takes time for response)
    //  needs to exists some flag
    // TODO how to combine it with auto-fetching item by id ?

    // TODO some "except ids" available ? (like skip those)

    // if (typeof state[name] !== "undefined") {
    //   let search_select = state[name];
    //
    //   if ((new Date().getTime() - search_select.fetchedAt) / 1000 <= 30) {
    //     // use state
    //     let search_select = state[name];
    //
    //     return search_select.data;
    //   }
    // }
  },
}

const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

let checkForUpdate = async function (getters, name) {
  // Check if status is done ...
  let status = getters.getStatus(name)

  if (status === 'loading') {
    await wait(500)
    await checkForUpdate(getters, name)
  }

  return true
}
