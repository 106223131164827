const API_URL = 'admin'

export const state = () => ({
  devices: { items: [] },
  users: [],
})

export const getters = {}

export const mutations = {
  SET_DEVICES(state, waste_company_devices) {
    state.devices = waste_company_devices
  },
  PUSH_OR_REPLACE_DEVICE(state, new_device) {
    let device_index = state.devices.items.findIndex(device => device.id === parseInt(new_device.id))

    if (device_index >= 0) {
      state.devices.items.splice(device_index, 1, new_device)
    } else {
      state.devices.items.push(new_device)
    }
  },
  SET_USERS(state, users) {
    state.users = users
  },
}

export const actions = {
  async fetchAllDevices({ commit }, params = null) {
    let devices = await this.$axios.$get(`${API_URL}/device`, {
      params: params,
    })

    commit('SET_DEVICES', devices)

    return devices
  },
  async storeDevice({ commit }, device) {
    device = await this.$axios.$post(`${API_URL}/device`, device)

    commit('PUSH_OR_REPLACE_DEVICE', device)

    return device
  },
  async fetchAllUsers({ commit }, params = null) {
    let users = await this.$axios.$get(`${API_URL}/user`, {
      params: params,
    })

    commit('SET_USERS', users.items)

    return users
  },
}
