const API_URL = '/user'

export const state = () => ({
  users: [],
  roles: [],
  branches: [],
  user_info: null,
  permissions: null,
})

export const getters = {
  getAll: state => {
    return state.users
  },
  get: state => user_id => {
    return state.users.find(user => user.id === parseInt(user_id))
  },
  getRoles: state => {
    return state.roles
  },
  getBranches: state => {
    return state.branches
  },
  getBranch: state => branch_id => {
    return state.branches.find(branch => branch.wasteCompanyBranchId === parseInt(branch_id))
  },
  getUserInfo: state => {
    return state.user_info
  },
  getUserPermissions: state => {
    return state.permissions
  },
}

export const mutations = {
  PUSH_OR_REPLACE_USER(state, new_user) {
    let user_index = state.users.findIndex(user => user.id === parseInt(new_user.id))

    if (user_index >= 0) {
      state.users.splice(user_index, 1, new_user)
    } else {
      state.users.push(new_user)
    }
  },
  SET_ROLES(state, roles) {
    state.roles = roles
  },
  SET_BRANCHES(state, branches) {
    state.branches = branches
  },
  SET_USER_INFO(state, userInfo) {
    state.user_info = userInfo
  },
  SET_USER_PERMISSIONS(state, permissions) {
    state.permissions = permissions
  },
}

export const actions = {
  async fetch({ commit }, user_id) {
    let user = await this.$axios.$get('/waste-company-users/' + user_id)

    commit('PUSH_OR_REPLACE_USER', user)

    return user
  },
  async update({ commit }, { user, user_id }) {
    await this.$axios.$put('/waste-company-users/' + user_id, user)

    commit('PUSH_OR_REPLACE_USER', user)

    return user
  },
  async deactivate({ commit }, { user, user_id }) {
    await this.$axios.$patch('/waste-company-users/' + user_id, user)

    commit('PUSH_OR_REPLACE_USER', user)

    return user
  },
  async fetchAllRoles({ commit }, params) {
    let roles = await this.$axios.$get(API_URL + '/roles', { params: params })

    commit('SET_ROLES', roles.items)

    return roles.items
  },
  async fetchAllCompanyBranches({ commit }) {
    let branches = await this.$axios.$get(API_URL + '/user-info/all-branches')

    commit('SET_BRANCHES', branches.items)

    return branches.items
  },
  async fetchUserInfo({ commit }) {
    let userInfo = await this.$axios.$get(API_URL + '/user-info')

    commit('SET_USER_INFO', userInfo)

    return userInfo
  },

  async fetchUserPermissions({ commit }) {
    let userPermissions = await this.$axios.$get(API_URL + '/user-info/permissions')
    userPermissions = userPermissions?.map(permission => permission.value) || []

    commit('SET_USER_PERMISSIONS', userPermissions)

    return userPermissions
  },

  async fetchUniqueUserName({ commit }, { firstName, lastName }) {
    return this.$axios.$get(API_URL + '/user-info/unique-user-name', {
      params: {
        FirstName: firstName,
        LastName: lastName,
      },
    })
  },
  async resendRegistrationEmail({ commit }, userId) {
    return this.$axios.$patch(`/waste-company-users/${userId}/resend-registration-email`)
  },
  async fetchUserProfile() {
    return await this.$axios.$get(`${API_URL}/profile`)
  },
  async updateUserProfile({ commit }, userProfile) {
    return await this.$axios.$put(`${API_URL}/profile`, userProfile)
  },
}
