import revive_payload_client_4sVQNw7RlN from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OcT8LIvbNC from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/node_modules/nuxt-bugsnag/dist/runtime/plugin.mjs";
import pwa_client_Eorgdr12jA from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_y9dIov5OEk from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/node_modules/nuxt3-vuex-module/src/plugin.js";
import templates_axios_plugin_7c977c1a_BK3OwKnOza from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/.nuxt/templates.axios.plugin.7c977c1a.mjs";
import http_plugin_nuxt_BmzkAX86rT from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import auth_plugin_6DDHMgc34l from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/.nuxt/auth.plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import axios_QMFgzss1s4 from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/plugins/axios.ts";
import gMaps_iQFi3rvy7X from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/plugins/gMaps.ts";
import global_2XP5sjpyNs from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/plugins/global.ts";
import project_79piUfRgjh from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/plugins/project.ts";
import router_WMnTGednOQ from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/plugins/router.ts";
import storePlugin_zVkiXMSffQ from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/plugins/storePlugin.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_OcT8LIvbNC,
  pwa_client_Eorgdr12jA,
  plugin_y9dIov5OEk,
  templates_axios_plugin_7c977c1a_BK3OwKnOza,
  http_plugin_nuxt_BmzkAX86rT,
  auth_plugin_6DDHMgc34l,
  chunk_reload_client_UciE0i6zes,
  axios_QMFgzss1s4,
  gMaps_iQFi3rvy7X,
  global_2XP5sjpyNs,
  project_79piUfRgjh,
  router_WMnTGednOQ,
  storePlugin_zVkiXMSffQ
]