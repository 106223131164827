const API_URL = '/dashboard'

export const state = () => ({
  general_info: {},
  note: {
    createdTs: '',
    value: '',
  },
  bins_without_code: { items: [] },
  bins_not_collected: { items: [] },
  bins_out_of_route: { items: [] },
  waste_collection_forecast: {},
  waste_collection_summary: {
    totalBinCount: 0,
    totalWasteQuantity: 0,
    totalCollectionTime: 0,
    data: [],
  },
  expiring_contracts: { items: [] },
  upcoming_resource_events: { items: [] },
})

export const getters = {
  getGeneralInfo: state => {
    return state.general_info
  },
  getNote: state => {
    return state.note
  },
  getBinsWithoutCode: state => {
    return state.bins_without_code
  },
  getBinsNotCollected: state => {
    return state.bins_not_collected
  },
  getBinsOutOfRoute: state => {
    return state.bins_out_of_route
  },
  getWasteCollectionForecast: state => {
    return state.waste_collection_forecast
  },
  getWasteCollectionSummary: state => {
    return state.waste_collection_summary
  },
  getExpiringContracts: state => {
    return state.expiring_contracts
  },
  getUpcomingResourceEvents: state => {
    return state.upcoming_resource_events
  },
}

export const mutations = {
  SET_GENERAL_INFO(state, info) {
    state.general_info = info
  },
  SET_NOTE(state, note) {
    state.note = note
  },
  SET_BINS_WITHOUT_CODE(state, data) {
    state.bins_without_code = data
  },
  SET_BINS_NOT_COLLECTED(state, data) {
    state.bins_not_collected = data
  },
  SET_BINS_OUT_OF_ROUTE(state, data) {
    state.bins_out_of_route = data
  },
  SET_WASTE_COLLECTION_FORECAST(state, data) {
    state.waste_collection_forecast = data
  },
  SET_WASTE_COLLECTION_SUMMARY(state, data) {
    state.waste_collection_summary = data
  },
  SET_EXPIRING_CONTRACTS(state, data) {
    state.expiring_contracts = data
  },
  SET_UPCOMING_RESOURCE_EVENTS(state, data) {
    state.upcoming_resource_events = data
  },
}

export const actions = {
  async fetchGeneralInfo({ commit }, waste_company_branch_id) {
    let generalInfo = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/general-info`)

    commit('SET_GENERAL_INFO', generalInfo)

    return generalInfo
  },
  async fetchNote({ commit }, waste_company_branch_id) {
    let note = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/note`)

    commit('SET_NOTE', note)

    return note
  },
  async updateNote({ commit }, { waste_company_branch_id, noteValue }) {
    await this.$axios.$post(`${API_URL}/${waste_company_branch_id}/note`, null, { params: { noteValue } })
    let note = { createdTs: new Date().toISOString(), value: noteValue }

    commit('SET_NOTE', note)

    return note
  },
  async fetchBinsWithoutCode({ commit }, { waste_company_branch_id, params }) {
    let bins = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/bin-count-collected-without-code`, {
      params: params,
    })

    commit('SET_BINS_WITHOUT_CODE', bins)

    return bins
  },
  async fetchBinsNotCollected({ commit }, { waste_company_branch_id, params }) {
    let bins = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/bin-count-not-collected`, {
      params: params,
    })

    commit('SET_BINS_NOT_COLLECTED', bins)

    return bins
  },
  async fetchBinsOutOfRoute({ commit }, { waste_company_branch_id, params }) {
    let bins = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/bin-count-out-of-route`, {
      params: params,
    })

    commit('SET_BINS_OUT_OF_ROUTE', bins)

    return bins
  },
  async fetchWasteCollectionForecast({ commit }, waste_company_branch_id) {
    let forecast = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/waste-collection-forecast`)

    commit('SET_WASTE_COLLECTION_FORECAST', forecast)

    return forecast
  },
  async fetchWasteCollectionSummary({ commit }, { waste_company_branch_id, params }) {
    let summary = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/waste-collection-summary`, {
      params: params,
    })

    commit('SET_WASTE_COLLECTION_SUMMARY', summary)

    return summary
  },
  async fetchExpiringContracts({ commit }, { waste_company_branch_id, params }) {
    let expiringContracts = await this.$axios.$get(
      `${API_URL}/${waste_company_branch_id}/waste-customer-contracts-close-to-end`,
      { params: params }
    )

    commit('SET_EXPIRING_CONTRACTS', expiringContracts)

    return expiringContracts
  },
  async fetchUpcomingResourceEvents({ commit }, { waste_company_branch_id, params }) {
    let events = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/upcomming-resorce-events`, {
      params: params,
    })

    commit('SET_UPCOMING_RESOURCE_EVENTS', events)

    return events
  },
}
