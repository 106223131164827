import Lang from 'lang.js'
import messages from '../../../assets/js/ll_messages'
import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import { Toast } from 'bootstrap/dist/js/bootstrap.esm.min'

// const helpers = {
//   // TODO how to access store && move it to helpers.js ??
//   fakeError(name, text) {
//     const errors = {
//       name: [text],
//     }
//
//     this.$store.dispatch('validation/setErrors', errors)
//   },
// }

export default defineNuxtPlugin(nuxtApp => {
  const { $store } = nuxtApp

  const trans = new Lang({
    messages,
    locale: $store.getters['global/getCountryCulture'] ?? 'cs',
    fallback: 'en',
  })

  function _(key: string, translation = null, count = null, defaultValue = null, replace = {}) {
    const slashPosition = key.indexOf('/')
    if (slashPosition > -1) {
      key = key.substring(slashPosition + 1)
    }
    if (trans.has(key)) {
      if (count) {
        return trans.choice(key, count, replace)
      }
      return trans.get(key, replace)
    }
    if (translation) {
      console.log(key)
      return translation
    }
    return key
  }

  const $this = {
    isPasswordStrong(pass) {
      if (!pass || pass.length === 0) return null
      // obsahuje male pismeno, velke pismeno, cislo a delka je alespon 6 znaku
      //const pattern = new RegExp("(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}");
      const pattern = new RegExp('((?=.*\\d)(?=.*[a-zA-Z])).{6,}')
      return pattern.test(pass)
    },

    userCan(...roles) {
      const user = window.$nuxt.$auth.user
      return typeof user === 'object' && user != null && roles.includes(user.role)
    },

    getCustomerDisplayName(customer) {
      if (!customer) return ''

      return customer.company_name || customer.full_name
    },

    validateIco(ico) {
      return !/^\d{8}$/.test(ico)
    },
    translateLabel(label, error) {
      if (Array.isArray(label) && label.length) label = label[0]

      let errorKey = null
      if (label && error) {
        // get name of validation error
        errorKey = label.getAttribute('for')
        if (errorKey) {
          errorKey = errorKey.charAt(0).toUpperCase() + errorKey.slice(1)
        }

        label = label.innerText.replace('*', '')
        label = label.trim()
        const quoteCount = error.match(/'/gm)
        if (quoteCount && quoteCount.length >= 4) {
          // replace last two quote marks if there are 4 or more
          error = $this.replaceLastCharAt(error, "'", '"')
          error = $this.replaceLastCharAt(error, "'", '"')
        }
        error = error.replace(/'.*'/gm, '"' + label + '"')
      }

      if (error) {
        // $store.dispatch('addErrorMessage', error.endsWith('.') ? error : `${error}.`)
        if (errorKey) $store.dispatch('validation/addTranslatedError', errorKey)
      }

      return error
    },
    replaceLastCharAt(str, char, replacement) {
      const index = str.lastIndexOf(char)
      return str.substr(0, index) + replacement + str.substr(index + replacement.length)
    },
    prefixImageUrl(path) {
      return useRuntimeConfig().public.API_URL + '/' + path
    },
    showToast(ref, message, status) {
      if (typeof ref === 'object' && ref.value !== undefined) ref = ref.value

      // Fill message
      const body = ref.getElementsByClassName('toast-body')[0]
      body.innerText = message

      // Change status
      const oldClass = ref.className.split(' ').filter(word => word.substring(0, 3) === 'bg-')[0]

      ref.classList.remove(oldClass)
      ref.classList.add(`bg-${status}`)

      const toast = new Toast(ref)
      toast.show()
    },
    /*
        Adds desired number of leading zeros to number
       */
    padNumber(num, size) {
      num = num.toString()
      while (num.length < size) num = '0' + num
      return num
    },
    removeWhiteSpace(string) {
      return string.replace(/\s/g, '')
    },
    formatNumberWithSpaces(number) {
      if (number === null || typeof number === 'undefined') number = 0
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    toSearchStr(str) {
      return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLocaleLowerCase()
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    /**
     * Clones object
     * https://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object
     * @param obj
     * @returns {{}|*[]|Date|*}
     */
    clone(obj) {
      let copy

      // Handle the 3 simple types, and null or undefined
      if (obj === null || typeof obj !== 'object') return obj

      // Handle Date
      if (obj instanceof Date) {
        copy = new Date()
        copy.setTime(obj.getTime())
        return copy
      }

      // Handle Array
      if (obj instanceof Array) {
        copy = []
        for (let i = 0, len = obj.length; i < len; i++) {
          copy[i] = $this.clone(obj[i])
        }
        return copy
      }

      // Handle Object
      if (obj instanceof Object) {
        copy = {}
        for (const attr in obj) {
          if (obj.hasOwnProperty(attr)) copy[attr] = $this.clone(obj[attr])
        }
        return copy
      }

      // Handle function
      if (obj instanceof Function) {
        copy = obj
        return copy
      }

      // Handle a typeof object
      if (typeof obj === 'object') {
        const entries = Object.entries(obj).map(([key, val]) => [key, $this.clone(val)])

        return Object.fromEntries(entries)
      }

      throw new Error("Unable to copy obj! Its type isn't supported.")
    },
    /**
     * Validates identification number (IČO)
     * @param identificationNumber
     * @param countryCode
     * @returns {boolean}
     */
    validateIdentificationNumber(identificationNumber, countryCode = 'CZ') {
      if (countryCode === 'CZ') {
        if (!identificationNumber || identificationNumber.length !== 8) return false
        let a = 0
        const b = identificationNumber.split('')
        let c = 0
        for (let i = 0; i < 7; i++) a += parseInt(b[i]) * (8 - i)
        a = a % 11
        c = 11 - a
        if (a === 1) c = 0
        if (a === 0) c = 1
        if (a === 10) c = 1

        return parseInt(b[7]) === c
      }
      if (countryCode === 'SK') {
        return !!identificationNumber && identificationNumber.length === 8
      }
      return !!identificationNumber
    },
    /**
     * Use this to register function that will handle PWA app refresh after deploy.
     * It's meant to be used in parent app's component, e.g. in default layout's mounted() or created() methods.
     * Default behavior is page reload if newer version of app exists -
     * this can be overridden by passing custom function as onUpdateFound parameter.
     * Requires @nuxtjs/pwa v3.3.5.
     * Example usage in default layout:
     * mounted() {
     *     this.$global.registerSwUpdate(this)
     * },
     * @param vueInstance component instance which will register route watcher
     * @param onUpdateFound function to be executed when update is found
     */
    registerSwUpdate(vueInstance, onUpdateFound = null) {
      // check if SW is supported
      if ('serviceWorker' in navigator) {
        if (!onUpdateFound) {
          onUpdateFound = () => {
            if (sessionStorage.getItem('firstVisit') === 'false') {
              console.info('new version - reloading...')
              window.location.reload()
            } else {
              sessionStorage.setItem('firstVisit', 'false')
              sessionStorage.setItem('updateWaiting', 'true')
            }
          }
        }

        // register update function
        navigator.serviceWorker.ready.then(reg => (reg.onupdatefound = onUpdateFound))
        // register watcher
        vueInstance.$watch('$route', () => {
          // trigger SW update event on route change
          navigator.serviceWorker.ready.then(reg => reg.update())
          if (sessionStorage.getItem('updateWaiting')) {
            sessionStorage.removeItem('updateWaiting')
            onUpdateFound()
          }
        })
      }
    },

    getTranslator() {
      return trans
    },

    /**
     * resolve local string (for toLocaleString fn) based on local storage
     * @return string
     */

    getLocalString() {
      switch ($store.getters['global/getCountryCulture']) {
        case 'cs':
          return 'cs-CZ'
        case 'sk':
          return 'sk-SK'
        default:
          return 'cs-CZ'
      }
    },

    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      )
    },
  }

  return {
    provide: {
      _: _,
      global: $this,
    },
  }
})
