import { defineNuxtRouteMiddleware, useNuxtApp, useRoute } from '#app'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { $auth, $project, $route } = useNuxtApp()

  if ($auth.loggedIn) {
    const splitPath = to.path.split('/')

    if (splitPath[0] === '') splitPath.splice(0, 1)
    const routeName = splitPath[0]
    const subPage = splitPath[splitPath.length - 1]

    const routePath = $project.getRoutePath(to.path)

    // determine required permission, 'read' is default
    let permission = 'r'
    if (subPage === 'create' || subPage === 'import') permission = 'c'
    else if (subPage === 'edit') permission = 'u'
    else if (subPage !== routeName && !isNaN(subPage)) permission = 's'

    if (!$project.isUserAllowed(permission, routePath)) {
      $project.get403Error()
    }
  }
})
