const API_URL = '/waste-company-car'

export const state = () => ({
  waste_company_cars: [],
  waste_collection_routes: {},
  waste_collections: {},
  car_event_history: {
    items: [],
  },
})

export const getters = {
  get: state => waste_company_car_id => {
    return state.waste_company_cars.find(waste_company_car => waste_company_car.id === parseInt(waste_company_car_id))
  },
}

export const mutations = {
  PUSH_OR_REPLACE_WASTE_COMPANY_CAR(state, new_waste_company_car) {
    let waste_company_car_index = state.waste_company_cars.findIndex(
      waste_company_car => waste_company_car.id === parseInt(new_waste_company_car.id)
    )

    if (waste_company_car_index >= 0) {
      state.waste_company_cars.splice(waste_company_car_index, 1, new_waste_company_car)
    } else {
      state.waste_company_cars.push(new_waste_company_car)
    }
  },
  REMOVE_WASTE_COMPANY_CAR(state, waste_company_car_id) {
    let waste_company_car_index = state.waste_company_cars.findIndex(
      waste_company_car => waste_company_car.id === parseInt(waste_company_car_id)
    )

    state.waste_company_cars.splice(waste_company_car_index, 1)
  },
  SET_WASTE_COLLECTION_ROUTES(state, routes) {
    state.waste_collection_routes = routes
  },
  SET_WASTE_COLLECTIONS(state, collections) {
    state.waste_collections = collections
  },
  SET_CAR_EVENT_HISTORY(state, events) {
    state.car_event_history = events
  },
  PUSH_OR_REPLACE_CAR_EVENT(state, new_event) {
    let event_index = state.car_event_history.items.findIndex(event => event.id === parseInt(new_event.id))

    if (event_index >= 0) {
      state.car_event_history.items.splice(event_index, 1, new_event)
    } else {
      state.car_event_history.items.push(new_event)
    }
  },
  REMOVE_CAR_EVENT(state, car_event_id) {
    let event_index = state.car_event_history.items.findIndex(event => event.id === parseInt(car_event_id))

    state.car_event_history.items.splice(event_index, 1)
  },
}

export const actions = {
  async fetch({ commit }, waste_company_car_id) {
    let waste_company_car = await this.$axios.$get(`${API_URL}/${waste_company_car_id}`)

    commit('PUSH_OR_REPLACE_WASTE_COMPANY_CAR', waste_company_car)

    return waste_company_car
  },
  async update({ commit }, waste_company_car) {
    waste_company_car = await this.$axios.$put(`${API_URL}/${waste_company_car.id}`, waste_company_car)

    commit('PUSH_OR_REPLACE_WASTE_COMPANY_CAR', waste_company_car)

    return waste_company_car
  },
  async delete({ commit }, waste_company_car_id) {
    await this.$axios.$delete(`${API_URL}/${waste_company_car_id}`)

    commit('REMOVE_WASTE_COMPANY_CAR', waste_company_car_id)
  },
  async fetchWasteCollections({ commit }, { waste_company_car_id, params }) {
    let collections = await this.$axios.get(`${API_URL}/${waste_company_car_id}/waste-collection`, { params: params })

    commit('SET_WASTE_COLLECTIONS', collections.data)

    return collections.data
  },
  async fetchCarEventHistory({ commit }, { waste_company_car_id, params }) {
    let events = await this.$axios.get(`${API_URL}/${waste_company_car_id}/event-history`, { params: params })

    commit('SET_CAR_EVENT_HISTORY', events.data)

    return events.data
  },
  async storeCarEvent({ commit }, { waste_company_car_id, car_event }) {
    await this.$axios.$post(`${API_URL}/${waste_company_car_id}/event-history`, car_event)
  },
  async deleteCarEvent({ commit }, { waste_company_car_id, car_event_id }) {
    await this.$axios.$delete(`${API_URL}/${waste_company_car_id}/event-history/${car_event_id}`)

    commit('REMOVE_CAR_EVENT', car_event_id)
  },
}
