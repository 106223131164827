const API_URL = '/waste-customer-contract'

export const state = () => ({
  waste_customer_contract: {},
})

export const getters = {
  get: state => {
    return state.waste_customer_contract
  },
}

export const mutations = {
  SET_WASTE_CUSTOMER_CONTRACT(state, contract) {
    state.waste_customer_contract = contract
  },
  REMOVE_WASTE_CUSTOMER_CONTRACT(state) {
    state.waste_customer_contract = {}
  },
}

export const actions = {
  async fetch({ commit }, waste_customer_contract_id) {
    let waste_customer_contract = await this.$axios.$get(`${API_URL}/${waste_customer_contract_id}`)

    commit('SET_WASTE_CUSTOMER_CONTRACT', waste_customer_contract)

    return waste_customer_contract
  },
  async update({ commit }, { waste_customer_contract, waste_customer_contract_id }) {
    waste_customer_contract = await this.$axios.$put(
      `${API_URL}/${waste_customer_contract_id}`,
      waste_customer_contract
    )

    commit('SET_WASTE_CUSTOMER_CONTRACT', waste_customer_contract)

    return waste_customer_contract
  },
  async delete({ commit }, waste_customer_contract_id) {
    await this.$axios.$delete(`${API_URL}/${waste_customer_contract_id}`)

    commit('REMOVE_WASTE_CUSTOMER_CONTRACT')
  },
}
