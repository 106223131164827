import validate from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45guard_45global from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/middleware/auth-guard.global.ts";
import clear_45global from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/middleware/clear.global.ts";
import init_45global from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/middleware/init.global.ts";
import redirect_45to_45the_45right_45domain_45global from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/middleware/redirect-to-the-right-domain.global.ts";
import manifest_45route_45rule from "/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45guard_45global,
  clear_45global,
  init_45global,
  redirect_45to_45the_45right_45domain_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "redirect-when-authenticated": () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/middleware/redirect-when-authenticated.ts"),
  auth: () => import("/Users/tomasrybensky/Documents/work/JRK-Digital-TS/.vapor/build/app/resources/nuxt/admin/node_modules/@nuxt-alt/auth/dist/runtime/core/middleware.mjs")
}