const API_URL = '/subject'

export const state = () => ({
  subject: {},
  wasteEvidenceHeaders: {},
})

export const getters = {}

export const mutations = {
  SET_SUBJECT(state, subject) {
    state.subject = subject
  },
  REMOVE_SUBJECT(state) {
    state.subject = {}
  },
  SET_WASTE_EVIDENCE_HEADERS(state, wasteEvidenceHeaders) {
    state.wasteEvidenceHeaders = wasteEvidenceHeaders
  },
}

export const actions = {
  async fetch({ commit }, subject_id) {
    let subject = await this.$axios.$get(`${API_URL}/${subject_id}`)

    commit('SET_SUBJECT', subject)

    return subject
  },
  async update({ commit }, { subject_id, subject }) {
    subject = await this.$axios.$put(`${API_URL}/${subject_id}`, subject)

    commit('SET_SUBJECT', subject)

    return subject
  },
  async delete({ commit }, { subject_id }) {
    await this.$axios.$delete(`${API_URL}/${subject_id}`)

    commit('REMOVE_SUBJECT')
  },
  async fetchWasteEvidenceHeaders({ commit }, { subject_id, params }) {
    let wasteEvidenceHeaders = await this.$axios.$get(`${API_URL}/${subject_id}/waste-evidence-header`, {
      params: params,
    })

    commit('SET_WASTE_EVIDENCE_HEADERS', wasteEvidenceHeaders)

    return wasteEvidenceHeaders
  },
}
