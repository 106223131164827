export const state = () => ({
  errorMessage: null,
  errorMessages: [],
  warningMessages: [],
  shouldClearError: false,
  shouldClearWarnings: false,
  successMessage: null,
  shouldClearSuccess: false,
})

export const getters = {
  getErrorMessage: state => {
    return [state.errorMessage, ...state.errorMessages].join(' ')
  },
  getWarningMessages: state => {
    return state.warningMessages
  },
  shouldClearError: state => {
    return state.shouldClearError
  },
  shouldClearWarnings: state => {
    return state.shouldClearWarnings
  },
  getSuccessMessage: state => {
    return state.successMessage
  },
  shouldClearSuccess: state => {
    return state.shouldClearSuccess
  },
}

export const mutations = {
  SET_ERROR_MESSAGE(state, params) {
    state.errorMessage = params.message
    state.shouldClearError = params.clear
  },
  SET_ERROR_MESSAGES(state, messages) {
    state.errorMessages = messages
  },
  ADD_ERROR_MESSAGE(state, message) {
    if (!state.errorMessages.find(item => item === message)) state.errorMessages.push(message)
  },
  SET_WARNING_MESSAGES(state, warnings) {
    state.warningMessages = warnings
    state.shouldClearWarnings = false
  },
  SET_SUCCESS_MESSAGE(state, params) {
    state.successMessage = params.message
    state.shouldClearSuccess = params.clear
  },
  MARK_ERROR_FOR_CLEARING(state, clear) {
    state.shouldClearError = clear
  },
  MARK_WARNING_FOR_CLEARING(state) {
    state.shouldClearWarnings = true
  },
  MARK_SUCCESS_FOR_CLEARING(state, clear) {
    state.shouldClearSuccess = clear
  },
}

export const actions = {
  setErrorMessage({ commit }, message, clear = true) {
    commit('SET_ERROR_MESSAGE', { message, clear })
  },
  addErrorMessage({ commit }, message) {
    commit('ADD_ERROR_MESSAGE', message)
  },
  setWarningMessages({ commit }, messages) {
    commit('SET_WARNING_MESSAGES', messages)
  },
  setSuccessMessage({ commit }, message, clear = true) {
    commit('SET_SUCCESS_MESSAGE', { message, clear })
  },
  clearErrorMessage({ commit }) {
    commit('SET_ERROR_MESSAGE', { message: null, clear: true })
    commit('SET_ERROR_MESSAGES', [])
  },
  clearWarningMessages({ commit }) {
    commit('SET_WARNING_MESSAGES', null)
  },
  clearSuccessMessage({ commit }) {
    commit('SET_SUCCESS_MESSAGE', { message: null, clear: true })
  },
  markErrorForClearing({ commit }, clear = true) {
    commit('MARK_ERROR_FOR_CLEARING', clear)
  },
  markWarningForClearing({ commit }) {
    commit('MARK_WARNING_FOR_CLEARING')
  },
  markSuccessForClearing({ commit }, clear = true) {
    commit('MARK_SUCCESS_FOR_CLEARING', clear)
  },
}
