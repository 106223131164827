const API_URL = '/waste-company-branches'

export const state = () => ({
  waste_company_branches: [],
  waste_collection_routes: { items: [] },
  waste_bins: [],
  waste_customers: { items: [] },
  waste_bins_filters: [],
  waste_collection_route_filter_options: [],
  wasteCustomers: [],
  waste_evidence_headers: {
    items: [],
  },
  waste_evidence_headers_filter_options: [],
  waste_company_workers: {
    items: [],
  },
  waste_company_cars: {
    items: [],
  },
  exceptional_waste_collections: {
    items: [],
  },
  waste_collections_filter_options: [],
  devices: {
    items: [],
  },
  external_services: {
    items: [],
  },
  route_calendar: {
    items: [],
  },
})

export const getters = {
  getAll: state => {
    return state.waste_company_branches
  },
  getAllAvailableBinsForRouteCreation: state => {
    return state.waste_bins
  },
  getAllCustomers: state => {
    return state.waste_customers
  },
  getAllAvailableBinsForRouteCreationCityFilter: state => {
    return state.waste_bins_filters['cities']
  },
  getAllAvailableBinsForRouteCreationStreetFilter: state => {
    return state.waste_bins_filters['streets']
  },
  getAllAvailableBinsForRouteCreationWasteCustomerFilter: state => {
    return state.waste_bins_filters['wasteCustomers']
  },
  getAllAvailableBinsForRouteCreationBinVolumeFilter: state => {
    return (state.waste_bins_filters['binVolumes'] ?? []).filter((binVolume, index, volumes) => {
      return volumes.findIndex(item => item.id === binVolume.id) === index
    })
  },
  get: state => waste_company_branch_id => {
    return state.waste_company_branches.find(
      waste_company_branch => waste_company_branch.id === parseInt(waste_company_branch_id)
    )
  },
  getExceptionalWasteCollections: state => {
    return state.exceptional_waste_collections
  },
  getRouteCalendarItems: state => {
    return state.route_calendar.items
  },
}

export const mutations = {
  SET_WASTE_COMPANY_BRANCHES(state, waste_company_branches) {
    state.waste_company_branches = waste_company_branches
  },
  SET_WASTE_BINS(state, waste_bins) {
    state.waste_bins = waste_bins
  },
  SET_WASTE_BINS_FILTERS(state, waste_bins_filters) {
    state.waste_bins_filters = waste_bins_filters
  },
  PUSH_OR_REPLACE_WASTE_COMPANY_BRANCH(state, new_waste_company_branch) {
    let waste_company_branch_index = state.waste_company_branches.findIndex(
      waste_company_branch => waste_company_branch.id === parseInt(new_waste_company_branch.id)
    )

    if (waste_company_branch_index >= 0) {
      state.waste_company_branches.splice(waste_company_branch_index, 1, new_waste_company_branch)
    } else {
      state.waste_company_branches.items.push(new_waste_company_branch)
    }
  },
  REMOVE_WASTE_COMPANY_BRANCH(state, waste_company_branch_id) {
    let waste_company_branch_index = state.waste_company_branches.findIndex(
      waste_company_branch => waste_company_branch.id === parseInt(waste_company_branch_id)
    )

    state.waste_company_branches.splice(waste_company_branch_index, 1)
  },

  PUSH_OR_REPLACE_WASTE_COLLECTION_ROUTE(state, new_waste_collection_route) {
    let waste_collection_route_index = state.waste_collection_routes.items.findIndex(
      waste_collection_route => waste_collection_route.id === parseInt(new_waste_collection_route.id)
    )

    if (waste_collection_route_index >= 0) {
      state.waste_collection_routes.items.splice(waste_collection_route_index, 1, new_waste_collection_route)
    } else {
      state.waste_collection_routes.items.push(new_waste_collection_route)
    }
  },
  PUSH_OR_REPLACE_CUSTOMER(state, new_customer) {
    let customer_index = state.waste_customers.items.findIndex(customer => customer.id === parseInt(new_customer.id))

    if (customer_index >= 0) {
      state.waste_customers.items.splice(customer_index, 1, new_customer)
    } else {
      state.waste_customers.items.push(new_customer)
    }
  },
  SET_WASTE_COLLECTION_ROUTES(state, waste_collection_routes) {
    state.waste_collection_routes = waste_collection_routes
  },
  SET_WASTE_COLLECTION_ROUTE_FILTER_OPTIONS(state, filter_options) {
    state.waste_collection_route_filter_options = filter_options
  },
  SET_WASTE_COMPANY_CUSTOMERS(state, customers) {
    state.waste_customers = customers
  },
  SET_WASTE_COMPANY_WASTE_EVIDENCE_HEADERS(state, wasteEvidenceHeaders) {
    state.waste_evidence_headers = wasteEvidenceHeaders
  },
  SET_WASTE_COMPANY_WASTE_EVIDENCE_HEADERS_FILTER_OPTIONS(state, data) {
    state.waste_evidence_headers_filter_options = data
  },
  PUSH_OR_REPLACE_WASTE_EVIDENCE_HEADER(state, new_waste_evidence_header) {
    let waste_evidence_header_index = state.waste_evidence_headers.items.findIndex(
      waste_evidence_header => waste_evidence_header.id === parseInt(new_waste_evidence_header.id)
    )

    if (waste_evidence_header_index >= 0) {
      state.waste_evidence_headers.items.splice(waste_evidence_header_index, 1, new_waste_evidence_header)
    } else {
      state.waste_evidence_headers.items.push(new_waste_evidence_header)
    }
  },
  REMOVE_WASTE_CUSTOMER(state, waste_customer_id) {
    let waste_customer_index = state.waste_customers.items.findIndex(
      waste_customer => waste_customer.id === parseInt(waste_customer_id)
    )

    if (waste_customer_index >= 0) state.waste_customers.items.splice(waste_customer_index, 1)
  },
  SET_WASTE_COMPANY_WORKERS(state, waste_company_workers) {
    state.waste_company_workers = waste_company_workers
  },
  PUSH_OR_REPLACE_WASTE_COMPANY_WORKER(state, new_worker) {
    let waste_company_worker_index = state.waste_company_workers.items.findIndex(
      waste_company_worker => waste_company_worker.id === parseInt(new_worker.id)
    )

    if (waste_company_worker_index >= 0) {
      state.waste_company_workers.items.splice(waste_company_worker_index, 1, new_worker)
    } else {
      state.waste_company_workers.items.push(new_worker)
    }
  },
  REMOVE_WASTE_COMPANY_WORKER(state, waste_company_worker_id) {
    let waste_company_worker_index = state.waste_company_workers.items.findIndex(
      waste_company_worker => waste_company_worker.id === parseInt(waste_company_worker_id)
    )

    state.waste_company_workers.items.splice(waste_company_worker_index, 1)
  },
  SET_WASTE_COMPANY_CARS(state, waste_company_cars) {
    state.waste_company_cars = waste_company_cars
  },
  PUSH_OR_REPLACE_WASTE_COMPANY_CAR(state, new_waste_company_car) {
    let waste_company_car_index = state.waste_company_cars.items.findIndex(
      waste_company_car => waste_company_car.id === parseInt(new_waste_company_car.id)
    )

    if (waste_company_car_index >= 0) {
      state.waste_company_cars.items.splice(waste_company_car_index, 1, new_waste_company_car)
    } else {
      state.waste_company_cars.items.push(new_waste_company_car)
    }
  },
  REMOVE_WASTE_COMPANY_CAR(state, waste_company_car_id) {
    let waste_company_car_index = state.waste_company_cars.items.findIndex(
      waste_company_car => waste_company_car.id === parseInt(waste_company_car_id)
    )

    state.waste_company_cars.items.splice(waste_company_car_index, 1)
  },
  SET_EXCEPTIONAL_WASTE_COLLECTIONS(state, waste_collections) {
    state.exceptional_waste_collections = waste_collections
  },
  SET_WASTE_COLLECTIONS_FILTER_OPTIONS(state, filter_options) {
    state.waste_collections_filter_options = filter_options
  },
  SET_DEVICES(state, devices) {
    state.devices = devices
  },
  SET_EXTERNAL_SERVICES(state, services) {
    state.external_services = services
  },
  PUSH_OR_REPLACE_EXTERNAL_SERVICE(state, newService) {
    let service_index = state.external_services.items.findIndex(service => service.id === parseInt(newService.id))

    if (service_index >= 0) {
      state.external_services.items.splice(service_index, 1, newService)
    } else {
      state.external_services.items.push(newService)
    }
  },
  SET_ROUTE_CALENDAR_ITEMS(state, items) {
    state.route_calendar.items = items.items
  },
}

export const actions = {
  async fetchAll({ commit }, params = null) {
    let waste_company_branches = await this.$axios.$get(API_URL, {
      params: params,
    })

    commit('SET_WASTE_COMPANY_BRANCHES', waste_company_branches)

    return waste_company_branches
  },
  async fetch({ commit }, waste_company_branch_id) {
    let waste_company_branch = await this.$axios.$get(API_URL + '/' + waste_company_branch_id)

    commit('PUSH_OR_REPLACE_WASTE_COMPANY_BRANCH', waste_company_branch)

    return waste_company_branch
  },
  async store({ commit }, waste_company_branch) {
    waste_company_branch.id = await this.$axios.$post(API_URL, waste_company_branch)

    commit('PUSH_OR_REPLACE_WASTE_COMPANY_BRANCH', waste_company_branch)

    return waste_company_branch
  },
  async update({ commit }, { waste_company_branch, waste_company_branch_id }) {
    await this.$axios.$put(API_URL + '/' + waste_company_branch_id, waste_company_branch)

    commit('PUSH_OR_REPLACE_WASTE_COMPANY_BRANCH', waste_company_branch)

    return waste_company_branch
  },
  async delete({ commit }, waste_company_branch_id) {
    await this.$axios.$delete(API_URL + '/' + waste_company_branch_id)

    commit('REMOVE_WASTE_COMPANY_BRANCH', waste_company_branch_id)
  },

  // Waste collection routes
  async fetchAllWasteCollectionRoutes({ commit }, { waste_company_branch_id, params = null }) {
    let waste_collection_routes = await this.$axios.$get(
      `${API_URL}/${waste_company_branch_id}/waste-collection-route`,
      {
        params: params,
      }
    )

    commit('SET_WASTE_COLLECTION_ROUTES', waste_collection_routes)

    return waste_collection_routes
  },
  async fetchAllWasteCollectionRouteFilterOptions({ commit }, { waste_company_branch_id, params = null }) {
    let filter_options = await this.$axios.$get(
      `${API_URL}/${waste_company_branch_id}/waste-collection-route/filter-options`,
      {
        params: params,
      }
    )

    commit('SET_WASTE_COLLECTION_ROUTE_FILTER_OPTIONS', filter_options)

    return filter_options
  },
  async storeWasteCollectionRoute({ commit }, { waste_company_branch_id, waste_collection_route }) {
    waste_collection_route.id = await this.$axios.$post(
      `${API_URL}/${waste_company_branch_id}/waste-collection-route`,
      waste_collection_route
    )

    commit('PUSH_OR_REPLACE_WASTE_COLLECTION_ROUTE', waste_collection_route)

    return waste_collection_route
  },
  async storeCustomer({ commit }, { waste_company_branch_id, customer }) {
    customer.id = await this.$axios.$post(`${API_URL}/${waste_company_branch_id}/waste-company-customer`, customer)

    commit('PUSH_OR_REPLACE_CUSTOMER', customer)

    return customer
  },
  async deleteCustomer({ commit }, { waste_company_branch_id, waste_customer_id }) {
    await this.$axios.$delete(`${API_URL}/${waste_company_branch_id}/waste-company-customer/${waste_customer_id}`)

    commit('REMOVE_WASTE_CUSTOMER', waste_customer_id)
  },
  async fetchAllAvailableBinsForRouteCreation({ commit }, { params, waste_company_branch_id }) {
    let waste_bins = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/available-bins-for-route-creation`, {
      params: params,
    })

    commit('SET_WASTE_BINS', waste_bins)

    return waste_bins
  },
  async fetchAllAvailableBinsForRouteCreationFilters(
    { commit },
    {
      waste_company_branch_id,
      binTypes,
      wasteCatalogId,
      City = null,
      Street = null,
      WasteCustomerId = null,
      BinId = null,
    }
  ) {
    let waste_bins_filters = await this.$axios.$get(
      `${API_URL}/${waste_company_branch_id}/available-bins-for-route-creation/filter-options`,
      {
        params: {
          binTypes,
          wasteCatalogId,
          City,
          Street,
          WasteCustomerId,
          BinId,
        },
      }
    )

    commit('SET_WASTE_BINS_FILTERS', waste_bins_filters)

    return waste_bins_filters
  },
  async fetchAllWasteCompanyCustomers({ commit }, { waste_company_branch_id, params }) {
    let customers = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/waste-company-customer`, {
      params: params,
    })

    commit('SET_WASTE_COMPANY_CUSTOMERS', customers)

    return customers
  },
  async fetchAllWasteEvidenceHeaders({ commit }, { waste_company_branch_id, params }) {
    let wasteEvidenceHeaders = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/waste-evidence-header`, {
      params: params,
    })

    commit('SET_WASTE_COMPANY_WASTE_EVIDENCE_HEADERS', wasteEvidenceHeaders)

    return wasteEvidenceHeaders
  },
  async fetchAllAvailableWasteEvidenceHeadersFilters({ commit }, waste_company_branch_id) {
    let filterOptions = await this.$axios.$get(
      `${API_URL}/${waste_company_branch_id}/waste-evidence-header/filter-options`
    )

    commit('SET_WASTE_COMPANY_WASTE_EVIDENCE_HEADERS_FILTER_OPTIONS', filterOptions)

    return filterOptions
  },
  async storeWasteEvidenceHeader({ commit }, { waste_company_branch_id, waste_evidence_header }) {
    waste_evidence_header.id = await this.$axios.$post(
      `${API_URL}/${waste_company_branch_id}/waste-evidence-header`,
      waste_evidence_header
    )

    commit('PUSH_OR_REPLACE_WASTE_EVIDENCE_HEADER', waste_evidence_header)

    return waste_evidence_header
  },
  async fetchAllWasteCompanyWorkers({ commit }, { waste_company_branch_id, params }) {
    let waste_company_workers = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/waste-company-worker`, {
      params: params,
    })

    commit('SET_WASTE_COMPANY_WORKERS', waste_company_workers)

    return waste_company_workers
  },
  async storeWasteCompanyWorker({ commit }, { waste_company_branch_id, worker }) {
    worker = await this.$axios.$post(`${API_URL}/${waste_company_branch_id}/waste-company-worker`, worker)

    commit('PUSH_OR_REPLACE_WASTE_COMPANY_WORKER', worker)

    return worker
  },
  async fetchAllWasteCompanyCars({ commit }, { waste_company_branch_id, params }) {
    let waste_company_cars = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/waste-company-car`, {
      params: params,
    })

    commit('SET_WASTE_COMPANY_CARS', waste_company_cars)

    return waste_company_cars
  },
  async storeWasteCompanyCar({ commit }, { waste_company_branch_id, car }) {
    car = await this.$axios.$post(`${API_URL}/${waste_company_branch_id}/waste-company-car`, car)

    commit('PUSH_OR_REPLACE_WASTE_COMPANY_CAR', car)

    return car
  },
  exportWasteEvidenceHeaders({ commit }, { waste_company_branch_id, params }) {
    let config = { params: params, responseType: 'blob' }
    return this.$axios.get(`${API_URL}/${waste_company_branch_id}/waste-evidence-header/export`, config)
  },
  async fetchAllExceptionalWasteCollections({ commit }, { waste_company_branch_id, params = null }) {
    let waste_collections = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/waste-collections`, {
      params: params,
    })

    commit('SET_EXCEPTIONAL_WASTE_COLLECTIONS', waste_collections)

    return waste_collections
  },
  async fetchAllWasteCollectionsFilterOptions({ commit }, { waste_company_branch_id, params = null }) {
    let filter_options = await this.$axios.$get(
      `${API_URL}/${waste_company_branch_id}/waste-collections/filter-options`,
      {
        params: params,
      }
    )

    commit('SET_WASTE_COLLECTIONS_FILTER_OPTIONS', filter_options)

    return filter_options
  },
  exportWasteCollectionRoutes({ commit }, { waste_company_branch_id, params }) {
    let config = { params: params, responseType: 'blob' }
    return this.$axios.get(`${API_URL}/${waste_company_branch_id}/waste-collection-route/export`, config)
  },
  exportWasteCustomers({ commit }, { waste_company_branch_id, params }) {
    let config = { params: params, responseType: 'blob' }
    return this.$axios.get(`${API_URL}/${waste_company_branch_id}/waste-company-customer/export`, config)
  },
  async fetchAllDevices({ commit }, { waste_company_branch_id, params }) {
    let devices = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/device`, {
      params: params,
    })

    commit('SET_DEVICES', devices)

    return devices
  },
  async fetchAllExternalServices({ commit }, { waste_company_branch_id, params }) {
    let services = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/external-service-connector`, {
      params: params,
    })

    commit('SET_EXTERNAL_SERVICES', services)

    return services
  },
  async storeExternalService({ commit }, externalService) {
    let service = await this.$axios.$post(`${API_URL}/external-service-connector`, externalService)

    commit('PUSH_OR_REPLACE_EXTERNAL_SERVICE', service)

    return service
  },
  async fetchRouteCalendarItems({ commit }, { waste_company_branch_id, params }) {
    let items = await this.$axios.$get(`${API_URL}/${waste_company_branch_id}/route-calendar`, {
      params: params,
    })

    commit('SET_ROUTE_CALENDAR_ITEMS', items)

    return items
  },
}
