const API_URL = '/external-service-connector'

export const state = () => ({})

export const getters = {}

export const mutations = {}

export const actions = {
  async fetch({ commit }, external_service_connector_id) {
    return await this.$axios.$get(`${API_URL}/${external_service_connector_id}`)
  },
  async update({ commit }, external_service_connector) {
    return await this.$axios.$put(`${API_URL}/${external_service_connector.id}`, external_service_connector)
  },
  async delete({ commit }, external_service_connector_id) {
    await this.$axios.$delete(`${API_URL}/${external_service_connector_id}`)
  },
}
