import { defineNuxtPlugin, useRuntimeConfig, useRouter } from '#app'

export default defineNuxtPlugin(async nuxtApp => {
  const { $axios, $store, $_, $auth } = nuxtApp
  const config = useRuntimeConfig()
  const router = useRouter()

  refreshCookie('auth._token.oidc')

  $axios.onRequest(config => {
    const token = useCookie('auth._token.oidc')

    if ($auth.$state.loggedIn) {
      config.headers = {
        ...config.headers,
        Authorization: token.value,
        Accept: 'application/json',
      }
    }
  })

  $axios.setBaseURL(config.public.API_URL + '/v1')

  $axios.onError(async error => {
    const originalRequest = error.config

    $store.dispatch('clearErrorMessage')

    const code = parseInt(error.response && error.response.status)

    if (code === 422) {
      $store.dispatch('validation/clearErrors')
      $store.dispatch('validation/setErrors', error.response.data.errors)

      return Promise.reject(error.response.data.errors)
    }

    if (code === 401) {
      if (!originalRequest.isRetry) {
        originalRequest.isRetry = true

        console.log('Refreshing token...')

        try {
          await $auth.refreshTokens()
        } catch (e) {
          console.error(e)

          // If refresh token is not successful, logout user and redirect to login page
          if ($auth.$state.loggedIn) await $auth.logout()
          await router.push('/login')
          return
        }

        refreshCookie('auth._token.oidc')

        originalRequest.headers = {
          ...originalRequest.headers,
          Authorization: useCookie('auth._token.oidc').value,
        }

        return $axios.request(originalRequest)
      } else {
        // If refresh token is not successful, logout user and redirect to login page
        if ($auth.$state.loggedIn) await $auth.logout()
        await router.push('/login')
      }
    }

    if (code === 403) {
      if (error.config.url.startsWith('/waste-evidence-header/') && error.config.method === 'put') {
        $store.dispatch(
          'setErrorMessage',
          $_('plugins/axios.update_header_unauthorized', 'Nelze přiřadit nádobu patřící jinému zákazníkovi.')
        )
        return Promise.reject(error)
      } else {
        $store.dispatch('setErrorMessage', $_('plugins/axios.403', 'Nedostatečné oprávnění pro provedení akce.'))
      }

      return Promise.reject(error)
    }

    if (code === 400) {
      $store.dispatch('setErrorMessage', error.response.data.Message)
      return Promise.reject(error)
    }
  })
})
