export const state = () => ({
  errors: {},
  generalErrors: {},
  translatedErrors: [],
  shouldClear: false,
})

export const getters = {
  getAll: state => {
    return state.errors
  },
  getAllTranslated: state => {
    return state.translatedErrors
  },
  getCount: state => {
    return state.errors.length
  },
  getFirst: (state, getters) => input => {
    if (getters.exists(input)) {
      return state.errors[input][0]
    }

    return null
  },
  exists: state => input => {
    return typeof state.errors[input] !== 'undefined'
  },
  shouldClear: state => {
    return state.shouldClear
  },
}

export const mutations = {
  SET_ERRORS(state, errors) {
    state.errors = errors
  },
  ADD_ERRORS(state, errors) {
    state.errors = { ...state.errors, ...errors }
  },
  ADD_TRANSLATED_ERROR(state, error) {
    if (!state.translatedErrors.includes(error)) state.translatedErrors.push(error)
  },
  REMOVE_ERROR(state, input) {
    if (typeof state.errors[input] !== 'undefined') {
      delete state.errors[input]
    }
  },
  REMOVE_TRANSLATED(state) {
    state.translatedErrors = []
  },
  MARK_FOR_CLEARING(state) {
    state.shouldClear = true
  },
}

export const actions = {
  setErrors({ commit }, errors) {
    commit('SET_ERRORS', errors)
  },
  addErrors({ commit }, errors) {
    commit('ADD_ERRORS', errors)
  },
  clearErrors({ commit, getters }) {
    let errors = getters['getAll']

    for (let error in errors) {
      commit('REMOVE_ERROR', error)
    }

    commit('REMOVE_TRANSLATED')
  },
  markForClearing({ commit }) {
    commit('MARK_FOR_CLEARING')
  },
  clearError({ commit }, error) {
    commit('REMOVE_ERROR', error)
  },
  addTranslatedError({ commit }, errorKey) {
    commit('ADD_TRANSLATED_ERROR', errorKey)
  },
}
