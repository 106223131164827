import {
  adminVuexStore,
  catalogVuexStore,
  dashboardVuexStore,
  devicesVuexStore,
  exportVuexStore,
  external_service_connectorVuexStore,
  globalVuexStore,
  indexVuexStore,
  search_selectsVuexStore,
  subjectsVuexStore,
  translationsVuexStore,
  usersVuexStore,
  validationVuexStore,
  waste_collection_routesVuexStore,
  waste_collectionsVuexStore,
  waste_companiesVuexStore,
  waste_company_branchesVuexStore,
  waste_company_carsVuexStore,
  waste_company_filesVuexStore,
  waste_company_workersVuexStore,
  waste_customer_contract_filesVuexStore,
  waste_customer_contractsVuexStore,
  waste_customersVuexStore,
  waste_evidence_headersVuexStore 
} from "#imports"
const VuexStore = {
  ...indexVuexStore,
  modules: {
      admin: {
        ...adminVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      catalog: {
        ...catalogVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      dashboard: {
        ...dashboardVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      devices: {
        ...devicesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      export: {
        ...exportVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      external_service_connector: {
        ...external_service_connectorVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      global: {
        ...globalVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      search_selects: {
        ...search_selectsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      subjects: {
        ...subjectsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      translations: {
        ...translationsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      users: {
        ...usersVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      validation: {
        ...validationVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      waste_collection_routes: {
        ...waste_collection_routesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      waste_collections: {
        ...waste_collectionsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      waste_companies: {
        ...waste_companiesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      waste_company_branches: {
        ...waste_company_branchesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      waste_company_cars: {
        ...waste_company_carsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      waste_company_files: {
        ...waste_company_filesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      waste_company_workers: {
        ...waste_company_workersVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      waste_customer_contract_files: {
        ...waste_customer_contract_filesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      waste_customer_contracts: {
        ...waste_customer_contractsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      waste_customers: {
        ...waste_customersVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      waste_evidence_headers: {
        ...waste_evidence_headersVuexStore,
        namespaced: true,
        modules: {},
      }
    }, 
  }
export default VuexStore