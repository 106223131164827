import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import { RuntimeConfig } from '@nuxt/schema'

export default defineNuxtPlugin(nuxtApp => {
  const config: RuntimeConfig = useRuntimeConfig()

  const params = {
    apiKey: config.public.GOOGLE_MAPS_API_KEY,
    loaded: false,
    libraries: ['drawing', 'visualization', 'places'],
  }

  return {
    provide: {
      GMaps: params,
    },
  }
})
