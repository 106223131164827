import { defineNuxtRouteMiddleware, useRuntimeConfig } from '#app'

export default defineNuxtRouteMiddleware(async (to, from) => {
  // Use this middleware only on pages with default layout
  if (to.meta.layout === undefined || to.meta.layout === 'default') {
    const requiredDomain = useRuntimeConfig().public.APP_URL
    const currentDomain = window.location.origin

    const fullPath = to.fullPath

    if (currentDomain !== requiredDomain) {
      redirectTo(`${requiredDomain}${fullPath}`)
    }
  }
})
