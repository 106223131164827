const API_URL = '/waste-company-files'

export const state = () => ({
  waste_company_files: [],
})

export const getters = {
  getAll: state => {
    return state.waste_company_files
  },
  get: state => waste_company_file_id => {
    return state.waste_company_files.find(
      waste_company_file => waste_company_file.id === parseInt(waste_company_file_id)
    )
  },
}

export const mutations = {
  PUSH_OR_REPLACE_WASTE_COMPANY_FILE(state, new_waste_company_file) {
    let waste_company_file_index = state.waste_company_files.findIndex(
      waste_company_file => waste_company_file.id === parseInt(new_waste_company_file.id)
    )

    if (waste_company_file_index >= 0) {
      state.waste_company_files.splice(waste_company_file_index, 1, new_waste_company_file)
    } else {
      state.waste_company_files.push(new_waste_company_file)
    }
  },
  REMOVE_WASTE_COMPANY_FILE(state, waste_company_file_id) {
    let waste_company_file_index = state.waste_company_files.findIndex(
      waste_company_file => waste_company_file.id === parseInt(waste_company_file_id)
    )

    state.waste_company_files.splice(waste_company_file_index, 1)
  },
}

export const actions = {
  async fetch({ commit }, waste_company_file_id) {
    let waste_company_file = await this.$axios.$get(API_URL + '/' + waste_company_file_id, { responseType: 'blob' })

    commit('PUSH_OR_REPLACE_WASTE_COMPANY_FILE', waste_company_file)

    return waste_company_file
  },
  async delete({ commit }, waste_company_file_id) {
    await this.$axios.$delete(API_URL + '/' + waste_company_file_id)

    commit('REMOVE_WASTE_COMPANY_FILE', waste_company_file_id)
  },
}
