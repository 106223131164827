const API_URL = '/waste-collection'

export const state = () => ({
  waste_collections: [],
  logs: [],
  logs_for_map: {
    items: [],
  },
  excel_file: null,
  customer_info: [],
  waste_collection_route_blueprint: null,
  waypoint: [],
})

export const getters = {
  getAll: state => {
    return state.waste_collections
  },
  get: state => waste_collection_id => {
    return state.waste_collections.find(waste_collection => waste_collection.id === parseInt(waste_collection_id))
  },
  getLogsForMap: state => {
    return state.logs_for_map
  },
  getLogs: state => {
    return state.logs
  },
  getCustomerInfo: state => {
    return state.customer_info
  },
  getWasteCollectionRouteBlueprint: state => {
    return state.waste_collection_route_blueprint
  },
  getWaypoint: state => {
    return state.waypoint
  },
}

export const mutations = {
  SET_WASTE_COLLECTIONS(state, waste_collections) {
    state.waste_collections = waste_collections
  },
  PUSH_OR_REPLACE_WASTE_COLLECTION(state, new_waste_collection) {
    let waste_collection_index = state.waste_collections.findIndex(
      waste_collection => waste_collection.id === parseInt(new_waste_collection.id)
    )

    if (waste_collection_index >= 0) {
      state.waste_collections.splice(waste_collection_index, 1, new_waste_collection)
    } else {
      state.waste_collections.push(new_waste_collection)
    }
  },
  SET_WASTE_COLLECTION_LOGS(state, logs) {
    state.logs = logs
  },
  SET_WASTE_COLLECTION_LOGS_FOR_MAP(state, logs) {
    state.logs_for_map = logs
  },
  SET_EXCEL_FILE(state, file) {
    state.excel_file = file
  },
  SET_CUSTOMER_INFO(state, info) {
    state.customer_info = info
  },
  SET_WASTE_COLLECTION_ROUTE_BLUEPRINT(state, blueprint_data) {
    state.waste_collection_route_blueprint = blueprint_data
  },
  SET_WASTE_COLLECTION_WAYPOINT(state, waypoint) {
    state.waypoint = waypoint
  },
}

export const actions = {
  async fetchAll({ commit }, params = null) {
    let waste_collections = await this.$axios.$get(API_URL, {
      params: params,
    })

    commit('SET_WASTE_COLLECTIONS', waste_collections)

    return waste_collections
  },
  async fetch({ commit }, waste_collection_id) {
    let waste_collection = await this.$axios.$get(API_URL + '/' + waste_collection_id)

    commit('PUSH_OR_REPLACE_WASTE_COLLECTION', waste_collection)

    return waste_collection
  },
  async update({ commit }, { waste_collection, waste_collection_id }) {
    await this.$axios.$put(API_URL + '/' + waste_collection_id, waste_collection)

    commit('PUSH_OR_REPLACE_WASTE_COLLECTION', waste_collection)

    return waste_collection
  },
  async fetchAllLogs({ commit }, { waste_collection_id, params = null }) {
    let logs = await this.$axios.$get(`${API_URL}/${waste_collection_id}/log-record`, { params: params })

    commit('SET_WASTE_COLLECTION_LOGS', logs)

    return logs
  },
  async fetchAllLogsForMap({ commit }, { waste_collection_id, params = null }) {
    let logs = await this.$axios.$get(`${API_URL}/${waste_collection_id}/log-record-for-map`, { params: params })

    commit('SET_WASTE_COLLECTION_LOGS_FOR_MAP', logs)

    return logs
  },
  async exportExcel({ commit }, { waste_collection_id, params = null }) {
    let config = { params: params, responseType: 'blob' }
    let file = await this.$axios.get(`${API_URL}/${waste_collection_id}/log-record/export`, config)

    commit('SET_EXCEL_FILE', file)

    return file
  },
  async fetchCustomerInfo({ commit }, waste_collection_id) {
    let info = await this.$axios.$get(`${API_URL}/${waste_collection_id}/customer-info`)

    commit('SET_CUSTOMER_INFO', info.items)

    return info.items
  },
  async fetchRouteCreateBlueprint({ commit }, waste_collection_id) {
    let blueprint_data = await this.$axios.$get(`${API_URL}/${waste_collection_id}/route-create-blueprint`)

    commit('SET_WASTE_COLLECTION_ROUTE_BLUEPRINT', blueprint_data)

    return blueprint_data
  },
  async fetchWaypoint({ commit }, { waste_collection_id, params }) {
    let waypoint = await this.$axios.$get(`${API_URL}/${waste_collection_id}/waypoint`, { params: params })

    commit('SET_WASTE_COLLECTION_WAYPOINT', waypoint.items)

    return waypoint.items
  },
  clearRouteCreateBlueprint({ commit }) {
    commit('SET_WASTE_COLLECTION_ROUTE_BLUEPRINT', null)
  },
  async updateWeight({ commit }, { waste_collection_id, weight }) {
    let data = new FormData()
    data.append('Weight', weight)
    await this.$axios.$put(`${API_URL}/${waste_collection_id}/weight`, data)
  },
}
