const API_URL = '/waste-company-worker'

export const state = () => ({
  waste_company_workers: [],
  waste_collection_routes: {},
  waste_collections: {},
})

export const getters = {
  get: state => waste_company_worker_id => {
    return state.waste_company_workers.find(
      waste_company_worker => waste_company_worker.id === parseInt(waste_company_worker_id)
    )
  },
}

export const mutations = {
  PUSH_OR_REPLACE_WASTE_COMPANY_WORKER(state, new_waste_company_worker) {
    let waste_company_worker_index = state.waste_company_workers.findIndex(
      waste_company_worker => waste_company_worker.id === parseInt(new_waste_company_worker.id)
    )

    if (waste_company_worker_index >= 0) {
      state.waste_company_workers.splice(waste_company_worker_index, 1, new_waste_company_worker)
    } else {
      state.waste_company_workers.push(new_waste_company_worker)
    }
  },
  REMOVE_WASTE_COMPANY_WORKER(state, waste_company_worker_id) {
    let waste_company_worker_index = state.waste_company_workers.findIndex(
      waste_company_worker => waste_company_worker.id === parseInt(waste_company_worker_id)
    )

    state.waste_company_workers.splice(waste_company_worker_index, 1)
  },
  SET_WASTE_COLLECTION_ROUTES(state, routes) {
    state.waste_collection_routes = routes
  },
  SET_WASTE_COLLECTIONS(state, collections) {
    state.waste_collections = collections
  },
}

export const actions = {
  async fetch({ commit }, waste_company_worker_id) {
    let waste_company_worker = await this.$axios.$get(`${API_URL}/${waste_company_worker_id}`)

    commit('PUSH_OR_REPLACE_WASTE_COMPANY_WORKER', waste_company_worker)

    return waste_company_worker
  },
  async update({ commit }, waste_company_worker) {
    waste_company_worker = await this.$axios.$put(`${API_URL}/${waste_company_worker.id}`, waste_company_worker)

    commit('PUSH_OR_REPLACE_WASTE_COMPANY_WORKER', waste_company_worker)

    return waste_company_worker
  },
  async delete({ commit }, waste_company_worker_id) {
    await this.$axios.$delete(`${API_URL}/${waste_company_worker_id}`)

    commit('REMOVE_WASTE_COMPANY_WORKER', waste_company_worker_id)
  },
  async fetchWasteCollectionRoutes({ commit }, { waste_company_worker_id, params }) {
    let routes = await this.$axios.get(`${API_URL}/${waste_company_worker_id}/waste-collection-route`, {
      params: params,
    })

    commit('SET_WASTE_COLLECTION_ROUTES', routes.data)

    return routes.data
  },
  async fetchWasteCollections({ commit }, { waste_company_worker_id, params }) {
    let collections = await this.$axios.get(`${API_URL}/${waste_company_worker_id}/waste-collection`, {
      params: params,
    })

    commit('SET_WASTE_COLLECTIONS', collections.data)

    return collections.data
  },
}
