const API_URL = '/waste-collection-route'

export const state = () => ({
  waste_collection_routes: {
    items: [],
  },
  waste_evidence_headers: [],
  dashboardData: [],
  wasteCollectionHistory: [],
  wasteCollectionCustomers: [],
})

export const getters = {
  getAll: state => {
    return state.waste_collection_routes
  },
  getAllWasteEvidenceHeaders: state => {
    return state.waste_evidence_headers
  },
  get: state => waste_collection_route_id => {
    return state.waste_collection_routes.items.find(
      waste_collection_route => waste_collection_route.id === parseInt(waste_collection_route_id)
    )
  },
  getWasteCollectionCustomers: state => {
    return state.wasteCollectionCustomers
  },
  getDashboardData: state => {
    return state.dashboardData
  },
}

export const mutations = {
  SET_WASTE_COLLECTION_ROUTES(state, waste_collection_routes) {
    state.waste_collection_routes = waste_collection_routes
  },
  PUSH_OR_REPLACE_WASTE_COLLECTION_ROUTE(state, new_waste_collection_route) {
    let waste_collection_route_index = state.waste_collection_routes.items.findIndex(
      waste_collection_route => waste_collection_route?.id === parseInt(new_waste_collection_route.id)
    )

    if (waste_collection_route_index >= 0) {
      state.waste_collection_routes.items.splice(waste_collection_route_index, 1, new_waste_collection_route)
    } else {
      state.waste_collection_routes.items.push(new_waste_collection_route)
    }
  },
  REMOVE_WASTE_COLLECTION_ROUTE(state, waste_collection_route_id) {
    let waste_collection_route_index = state.waste_collection_routes.items.findIndex(
      waste_collection_route => waste_collection_route.id === parseInt(waste_collection_route_id)
    )

    state.waste_collection_routes.items.splice(waste_collection_route_index, 1)
  },
  SET_WASTE_COLLECTION_ROUTE_DASHBOARD_DATA(state, data) {
    state.dashboardData = data
  },
  SET_WASTE_COLLECTION_ROUTE_HISTORY(state, data) {
    state.wasteCollectionHistory = data
  },
  SET_WASTE_COLLECTION_ROUTE_CUSTOMERS(state, data) {
    state.wasteCollectionCustomers = data
  },
  SET_WASTE_EVIDENCE_HEADERS(state, waste_evidence_headers) {
    state.waste_evidence_headers = waste_evidence_headers
  },
}

export const actions = {
  async fetch({ commit }, waste_collection_route_id) {
    let waste_collection_route = await this.$axios.$get(API_URL + '/' + waste_collection_route_id)

    commit('PUSH_OR_REPLACE_WASTE_COLLECTION_ROUTE', waste_collection_route)

    return waste_collection_route
  },
  /**
   * Creates new waste collection route based on exceptional waste collection
   * @param commit
   * @param exceptional_waste_collection_id
   * @returns {Promise<*>}
   */
  async createWasteCollectionRoute({ commit }, exceptional_waste_collection_id) {
    let formData = new FormData()
    formData.append('wasteCollectionId', exceptional_waste_collection_id)
    let waste_collection_route = await this.$axios.$post(API_URL, formData)

    commit('PUSH_OR_REPLACE_WASTE_COLLECTION_ROUTE', waste_collection_route)

    return waste_collection_route
  },
  async update({ commit }, { waste_collection_route, waste_collection_route_id }) {
    await this.$axios.$put(API_URL + '/' + waste_collection_route_id, waste_collection_route)

    commit('PUSH_OR_REPLACE_WASTE_COLLECTION_ROUTE', waste_collection_route)

    return waste_collection_route
  },
  async delete({ commit }, waste_collection_route_id) {
    await this.$axios.$delete(API_URL + '/' + waste_collection_route_id)

    commit('REMOVE_WASTE_COLLECTION_ROUTE', waste_collection_route_id)
  },
  async fetchDashboardData({ commit }, waste_collection_route_id) {
    let data = await this.$axios.$get(`${API_URL}/${waste_collection_route_id}/dashboard`)

    commit('SET_WASTE_COLLECTION_ROUTE_DASHBOARD_DATA', data)

    return data
  },
  async fetchWasteCollectionHistory({ commit }, { params, waste_collection_route_id }) {
    let data = await this.$axios.$get(`${API_URL}/${waste_collection_route_id}/waste-collection`, { params: params })

    commit('SET_WASTE_COLLECTION_ROUTE_HISTORY', data)

    return data
  },
  async fetchWasteCollectionCustomers({ commit }, waste_collection_route_id) {
    let data = await this.$axios.$get(`${API_URL}/${waste_collection_route_id}/customers-bin`)

    commit('SET_WASTE_COLLECTION_ROUTE_CUSTOMERS', data.items)

    return data.items
  },
  async fetchWasteEvidenceHeader({ commit }, waste_collection_route_id) {
    let data = await this.$axios.$get(`${API_URL}/${waste_collection_route_id}/waste-evidence-header`)

    commit('SET_WASTE_EVIDENCE_HEADERS', data.items)

    return data.items
  },
  exportExcel({ commit }, waste_collection_route_id) {
    return this.$axios.get(`${API_URL}/${waste_collection_route_id}/waste-collection/export`, { responseType: 'blob' })
  },
}
