const API_URL = '/waste-companies'

export const state = () => ({
  waste_companies: { items: [] },
  waste_company_branches: [],
  waste_company_attachments: [],
  waste_company_users: {
    items: [],
  },
  waste_company_logo: null,
  newly_created_user_login: null,
})

export const getters = {
  getAll: state => {
    return state.waste_companies
  },
  get: state => waste_company_id => {
    return state.waste_companies.items.find(waste_company => waste_company.id === parseInt(waste_company_id))
  },
  getLogo: state => {
    return state.waste_company_logo
  },
  getWasteCompanyBranches: state => state.waste_company_branches,
  getWasteCompanyAttachments: state => state.waste_company_attachments,
  getNewlyCreatedUserLogin: state => state.newly_created_user_login,
}

export const mutations = {
  SET_WASTE_COMPANIES(state, waste_companies) {
    state.waste_companies = waste_companies
  },
  PUSH_OR_REPLACE_WASTE_COMPANY(state, new_waste_company) {
    let waste_company_index = state.waste_companies.items.findIndex(
      waste_company => waste_company.id && waste_company.id === parseInt(new_waste_company.id)
    )

    if (waste_company_index >= 0) {
      state.waste_companies.items.splice(waste_company_index, 1, new_waste_company)
    } else {
      state.waste_companies.items.push(new_waste_company)
    }
  },
  REMOVE_WASTE_COMPANY(state, waste_company_id) {
    let waste_company_index = state.waste_companies.items.findIndex(
      waste_company => waste_company.id === parseInt(waste_company_id)
    )

    state.waste_companies.items.splice(waste_company_index, 1)
  },
  SET_WASTE_COMPANY_BRANCHES(state, waste_company_branches) {
    state.waste_company_branches = waste_company_branches
  },
  PUSH_OR_REPLACE_WASTE_COMPANY_BRANCH(state, new_waste_company_branch) {
    let waste_company_branch_index = state.waste_company_branches.findIndex(
      waste_company_branch => waste_company_branch.id === parseInt(new_waste_company_branch.id)
    )

    if (waste_company_branch_index >= 0) {
      state.waste_company_branches.splice(waste_company_branch_index, 1, new_waste_company_branch)
    } else {
      state.waste_company_branches.push(new_waste_company_branch)
    }
  },
  REMOVE_WASTE_COMPANY_BRANCH(state, waste_company_branch_id) {
    let waste_company_branch_index = state.waste_company_branches.findIndex(
      waste_company_branch => waste_company_branch.id === parseInt(waste_company_branch_id)
    )

    state.waste_company_branches.splice(waste_company_branch_index, 1)
  },
  SET_WASTE_COMPANY_USERS(state, users) {
    state.waste_company_users = users
  },
  SET_WASTE_COMPANY_ATTACHMENTS(state, attachments) {
    state.waste_company_attachments = attachments
  },
  PUSH_OR_REPLACE_WASTE_COMPANY_USER(state, new_waste_company_user) {
    let waste_company_user_index = state.waste_company_users.items.findIndex(
      waste_company_user => waste_company_user.id === parseInt(new_waste_company_user.id)
    )

    if (waste_company_user_index >= 0) {
      state.waste_company_users.items.splice(waste_company_user_index, 1, new_waste_company_user)
    } else {
      state.waste_company_users.items.push(new_waste_company_user)
    }
  },
  SET_WASTE_COMPANY_LOGO(state, logo) {
    state.waste_company_logo = logo
  },
  DELETE_WASTE_COMPANY_LOGO(state) {
    state.waste_company_logo = null
  },
  SET_NEWLY_CREATED_USER_LOGIN(state, login) {
    state.newly_created_user_login = login
  },
}

export const actions = {
  async fetchAll({ commit }, params = null) {
    let waste_companies = await this.$axios.$get(API_URL, {
      params: params,
    })

    commit('SET_WASTE_COMPANIES', waste_companies)

    return waste_companies
  },
  async fetch({ commit }, waste_company_id) {
    let waste_company = await this.$axios.$get(API_URL + '/' + waste_company_id)

    commit('PUSH_OR_REPLACE_WASTE_COMPANY', waste_company)

    return waste_company
  },
  async store({ commit }, waste_company) {
    waste_company.id = await this.$axios.$post(API_URL, waste_company)

    commit('PUSH_OR_REPLACE_WASTE_COMPANY', waste_company)

    return waste_company
  },
  async update({ commit }, { waste_company, waste_company_id }) {
    await this.$axios.$put(API_URL + '/' + waste_company_id, waste_company)

    commit('PUSH_OR_REPLACE_WASTE_COMPANY', waste_company)

    return waste_company
  },
  async delete({ commit }, waste_company_id) {
    await this.$axios.$delete(API_URL + '/' + waste_company_id)

    commit('REMOVE_WASTE_COMPANY', waste_company_id)
  },

  async fetchAllBranches({ commit }, waste_company_id) {
    let branches = await this.$axios.$get(`${API_URL}/${waste_company_id}/branches`)

    commit('SET_WASTE_COMPANY_BRANCHES', branches.items)

    return branches.items
  },
  async storeBranch({ commit }, { waste_company_branch, waste_company_id }) {
    waste_company_branch.id = await this.$axios.$post(`${API_URL}/${waste_company_id}/branches`, waste_company_branch)

    commit('PUSH_OR_REPLACE_WASTE_COMPANY_BRANCH', waste_company_branch)

    return waste_company_branch
  },
  async fetchAllUsers({ commit }, { params, waste_company_id }) {
    let users = await this.$axios.$get(`${API_URL}/${waste_company_id}/users`, {
      params: params,
    })

    commit('SET_WASTE_COMPANY_USERS', users)

    return users
  },
  async fetchAllAttachments({ commit }, waste_company_id) {
    let attachments = await this.$axios.$get(`${API_URL}/${waste_company_id}/attachments`)

    commit('SET_WASTE_COMPANY_ATTACHMENTS', attachments.items)

    return attachments.items
  },

  async storeAttachments({ commit }, { attachments, waste_company_id }) {
    let formData = new FormData()
    attachments.forEach(attachment => {
      formData.append('files', attachment)
    })

    await this.$axios.$post(`${API_URL}/${waste_company_id}/attachments`, formData)

    return attachments
  },
  async storeWasteCompanyUser({ commit }, { waste_company_user, waste_company_id }) {
    waste_company_user.id = await this.$axios.$post(`${API_URL}/${waste_company_id}/users`, waste_company_user)

    commit('PUSH_OR_REPLACE_WASTE_COMPANY_USER', waste_company_user)

    return waste_company_user
  },
  async storeWasteCompanyUserByMail({ commit }, { waste_company_user, waste_company_id }) {
    waste_company_user.id = await this.$axios.$post(
      `${API_URL}/${waste_company_id}/users/register-by-email`,
      waste_company_user
    )

    commit('PUSH_OR_REPLACE_WASTE_COMPANY_USER', waste_company_user)

    return waste_company_user
  },
  async storeLogo({ commit }, { logo, waste_company_id }) {
    let formData = new FormData()
    formData.append('file', logo)

    logo.id = await this.$axios.$post(`${API_URL}/${waste_company_id}/logo`, formData)

    commit('SET_WASTE_COMPANY_LOGO', logo)

    return logo
  },
  async deleteLogo({ commit }, waste_company_id) {
    await this.$axios.$delete(`${API_URL}/${waste_company_id}/logo`)

    commit('DELETE_WASTE_COMPANY_LOGO')
  },
  async fetchLogo({ commit }, waste_company_id) {
    let logo = await this.$axios.$get(`${API_URL}/${waste_company_id}/logo`)

    commit('SET_WASTE_COMPANY_LOGO', logo)

    return logo
  },
  exportWasteCompanies({ commit }, params) {
    let config = { params: params, responseType: 'blob' }
    return this.$axios.get(`${API_URL}/export`, config)
  },
  async fetchNewlyCreatedUserLogin({ commit }, user_id) {
    this.dispatch('users/fetch', user_id).then(user => {
      commit('SET_NEWLY_CREATED_USER_LOGIN', user.userName)

      return user.userName
    })
  },
  clearNewlyCreatedUserLogin({ commit }) {
    commit('SET_NEWLY_CREATED_USER_LOGIN', null)
  },
}
