const API_URL = '/waste-customer'

export const state = () => ({
  waste_customers: [],
  waste_collection_history: [],
  waste_collection_routes: [],
  contracts: { items: [] },
  chart_data: [],
  chart_data_filter_options: [],
  subjects: { items: [] },
})

export const getters = {
  get: state => waste_customer_id => {
    return state.waste_customers.find(waste_customer => waste_customer.id === parseInt(waste_customer_id))
  },
  getChartFilterOptions: state => {
    return state.chart_data_filter_options
  },
}

export const mutations = {
  PUSH_OR_REPLACE_WASTE_CUSTOMER(state, new_waste_customer) {
    let waste_customer_index = state.waste_customers.findIndex(
      waste_customer => waste_customer.id === parseInt(new_waste_customer.id)
    )

    if (waste_customer_index >= 0) {
      state.waste_customers.splice(waste_customer_index, 1, new_waste_customer)
    } else {
      state.waste_customers.push(new_waste_customer)
    }
  },
  REMOVE_WASTE_CUSTOMER(state, waste_customer_id) {
    let waste_customer_index = state.waste_customers.findIndex(
      waste_customer => waste_customer.id === parseInt(waste_customer_id)
    )

    state.waste_customers.splice(waste_customer_index, 1)
  },
  PUSH_OR_REPLACE_WASTE_COLLECTION_HISTORY(state, history) {
    state.waste_collection_history = history
  },
  PUSH_OR_REPLACE_WASTE_COLLECTION_ROUTES(state, routes) {
    state.waste_collection_routes = routes
  },
  PUSH_OR_REPLACE_CONTRACTS(state, contracts) {
    state.contracts = contracts
  },
  PUSH_OR_REPLACE_CHART_DATA(state, data) {
    state.chart_data = data
  },
  PUSH_OR_REPLACE_CHART_FILTER_OPTIONS(state, filterOptions) {
    state.chart_data_filter_options = filterOptions
  },
  PUSH_OR_REPLACE_WASTE_CUSTOMER_CONTRACT(state, new_waste_customer_contract) {
    let waste_customer_contract_index = state.contracts.items.findIndex(
      waste_customer_contract => waste_customer_contract.id === parseInt(new_waste_customer_contract.id)
    )

    if (waste_customer_contract_index >= 0) {
      state.contracts.items.splice(waste_customer_contract_index, 1, new_waste_customer_contract)
    } else {
      state.contracts.items.push(new_waste_customer_contract)
    }
  },
  PUSH_OR_REPLACE_SUBJECTS(state, subjects) {
    state.subjects = subjects
  },
  PUSH_OR_REPLACE_SUBJECT(state, new_subject) {
    let subject_index = state.subjects.items.findIndex(subject => subject.id === parseInt(new_subject.id))

    if (subject_index >= 0) {
      state.subjects.items.splice(subject_index, 1, new_subject)
    } else {
      state.subjects.items.push(new_subject)
    }
  },
}

export const actions = {
  async fetch({ commit }, waste_customer_id) {
    let waste_customer = await this.$axios.$get(API_URL + '/' + waste_customer_id)

    commit('PUSH_OR_REPLACE_WASTE_CUSTOMER', waste_customer)

    return waste_customer
  },
  async update({ commit }, { waste_customer, waste_customer_id }) {
    waste_customer = await this.$axios.$put(API_URL + '/' + waste_customer_id, waste_customer)

    commit('PUSH_OR_REPLACE_WASTE_CUSTOMER', waste_customer)

    return waste_customer
  },
  async delete({ commit }, waste_customer_id) {
    await this.$axios.$delete(API_URL + '/' + waste_customer_id)

    commit('REMOVE_WASTE_CUSTOMER', waste_customer_id)
  },
  async fetchWasteCollectionHistory({ commit }, { waste_customer_id, params }) {
    let wasteCollectionHistory = await this.$axios.$get(`${API_URL}/${waste_customer_id}/waste-collection`, {
      params: params,
    })

    commit('PUSH_OR_REPLACE_WASTE_COLLECTION_HISTORY', wasteCollectionHistory)

    return wasteCollectionHistory
  },
  async fetchWasteCollectionRoutes({ commit }, { waste_customer_id, params }) {
    let wasteCollectionRoutes = await this.$axios.$get(`${API_URL}/${waste_customer_id}/waste-collection-route`, {
      params: params,
    })

    commit('PUSH_OR_REPLACE_WASTE_COLLECTION_ROUTES', wasteCollectionRoutes)

    return wasteCollectionRoutes
  },
  async fetchWasteCollectionRoutesForBranch({ commit }, { waste_customer_id, waste_company_branch_id, params }) {
    let wasteCollectionRoutes = await this.$axios.$get(
      `${API_URL}/${waste_customer_id}/waste-collection-route/${waste_company_branch_id}`,
      {
        params: params,
      }
    )

    commit('PUSH_OR_REPLACE_WASTE_COLLECTION_ROUTES', wasteCollectionRoutes)

    return wasteCollectionRoutes
  },
  async fetchContracts({ commit }, { waste_customer_id, waste_company_branch_id }) {
    let contracts = await this.$axios.$get(
      `${API_URL}/${waste_customer_id}/waste-customer-contract/${waste_company_branch_id}`
    )

    commit('PUSH_OR_REPLACE_CONTRACTS', contracts)

    return contracts
  },
  async fetchChartData({ commit }, { waste_customer_id, params }) {
    let chartData = await this.$axios.$get(`${API_URL}/${waste_customer_id}/waste-evidence`, { params: params })

    commit('PUSH_OR_REPLACE_CHART_DATA', chartData)

    return chartData
  },
  async fetchChartFilterOptions({ commit }, waste_customer_id) {
    let filterOptions = await this.$axios.$get(`${API_URL}/${waste_customer_id}/waste-evidence/filter-options`)

    commit('PUSH_OR_REPLACE_CHART_FILTER_OPTIONS', filterOptions)

    return filterOptions
  },
  async storeWasteCustomerContract(
    { commit },
    { waste_customer_id, waste_company_branch_id, waste_customer_contract }
  ) {
    waste_customer_contract.id = await this.$axios.$post(
      `${API_URL}/${waste_customer_id}/waste-customer-contract/${waste_company_branch_id}`,
      waste_customer_contract
    )

    commit('PUSH_OR_REPLACE_WASTE_CUSTOMER_CONTRACT', waste_customer_contract)

    return waste_customer_contract
  },
  async fetchSubjects({ commit }, { waste_customer_id, params }) {
    let subjects = await this.$axios.$get(`${API_URL}/${waste_customer_id}/subject`, { params: params })

    commit('PUSH_OR_REPLACE_SUBJECTS', subjects)

    return subjects
  },
  async storeSubject({ commit }, { waste_customer_id, subject }) {
    subject.id = await this.$axios.$post(`${API_URL}/${waste_customer_id}/subject`, subject)

    commit('PUSH_OR_REPLACE_SUBJECT', subject)

    return subject
  },
}
