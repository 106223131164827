import { defineNuxtRouteMiddleware, useNuxtApp } from '#app'

export default defineNuxtRouteMiddleware(async () => {
  const { $store, $auth, $global } = useNuxtApp()

  if ($auth.loggedIn) {
    // set initial waste company branch id & fetch all branches
    const wasteCompanyBranchId = $store.getters['global/getWasteCompanyBranchId']
    if (wasteCompanyBranchId) {
      if (!$store.getters['users/getUserInfo']) {
        await $store
          .dispatch('users/fetchUserInfo')
          .then(async info => {
            const asAdmin = info.roles.some(role => $store.$project.adminRoles().includes(role.name))

            // when accessing the app as admin, set custom waste company branch id, othwerwise use the one from user info
            if (asAdmin) {
              await $store.dispatch('global/setWasteCompanyBranchId', wasteCompanyBranchId)
            } else if ($store.getters['global/getWasteCompanyBranchId'] !== wasteCompanyBranchId) {
              await $store.dispatch('global/setWasteCompanyBranchId', info.wasteCompanyBranch.id)
            }

            if ($store.getters['users/getBranches']) await $store.dispatch('users/fetchAllCompanyBranches')

            const culture =
              $store.getters['users/getBranch'](wasteCompanyBranchId)?.wasteCompanyCulture ??
              info.wasteCompanyBranch.culture ??
              'cs'
            $store.dispatch('global/setCountryCulture', culture)
            $global.getTranslator().setLocale(culture)

            let countryCode = null
            if (culture === 'cs') countryCode = 'CZ'
            else if (culture === 'sk') countryCode = 'SK'

            if (countryCode) {
              $store.dispatch('global/setCountryCode', countryCode)
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    } else {
      if (!$store.getters['global/getWasteCompanyBranchId'] || !$store.getters['users/getUserInfo']) {
        await $store
          .dispatch('users/fetchUserInfo')
          .then(async info => {
            await $store.dispatch('global/setWasteCompanyBranchId', info.wasteCompanyBranch.id)
            await $store.dispatch('users/fetchAllCompanyBranches', info.wasteCompanyBranch.id)
          })
          .catch(error => {
            console.log(error)
          })
      }
    }

    if (!$store.getters['users/getUserPermissions']) {
      await $store.dispatch('users/fetchUserPermissions')
    }
  }
})
