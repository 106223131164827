const API_URL = '/waste-customer-contract-file'

export const state = () => ({
  file: null,
})

export const getters = {
  get: state => {
    return state.file
  },
}

export const mutations = {
  SET_WASTE_CUSTOMER_CONTRACT_FILE(state, file) {
    state.file = file
  },
  REMOVE_WASTE_CUSTOMER_CONTRACT_FILE(state) {
    state.file = null
  },
}

export const actions = {
  fetch({ commit }, waste_customer_contract_file_id) {
    return this.$axios.$get(`${API_URL}/${waste_customer_contract_file_id}`, {
      responseType: 'blob',
    })
  },
  async store({ commit }, waste_customer_contract_file) {
    let formData = new FormData()
    formData.append('file', waste_customer_contract_file)

    waste_customer_contract_file.id = await this.$axios.$post(API_URL, formData)

    commit('SET_WASTE_CUSTOMER_CONTRACT_FILE', waste_customer_contract_file)

    return waste_customer_contract_file
  },
  async delete({ commit }, waste_customer_contract_file_id) {
    await this.$axios.$delete(`${API_URL}/${waste_customer_contract_file_id}`)

    commit('REMOVE_WASTE_CUSTOMER_CONTRACT_FILE')
  },
}
