const API_URL = 'device'

export const state = () => ({
  waste_company_devices: [],
  assign_history: {
    items: [],
  },
  device: {},
})

export const getters = {
  get: state => waste_company_device_id => {
    return state.waste_company_devices.find(
      waste_company_device => waste_company_device.id === parseInt(waste_company_device_id)
    )
  },
  getAssignHistory: state => {
    return state.assign_history
  },
  getDevice: state => {
    return state.device
  },
}

export const mutations = {
  PUSH_OR_REPLACE_WASTE_COMPANY_DEVICE(state, new_waste_company_device) {
    let waste_company_device_index = state.waste_company_devices.findIndex(
      waste_company_device => waste_company_device.id === parseInt(new_waste_company_device.id)
    )

    if (waste_company_device_index >= 0) {
      state.waste_company_devices.splice(waste_company_device_index, 1, new_waste_company_device)
    } else {
      state.waste_company_devices.push(new_waste_company_device)
    }
  },
  REMOVE_WASTE_COMPANY_DEVICE(state, waste_company_device_id) {
    let waste_company_device_index = state.waste_company_devices.findIndex(
      waste_company_device => waste_company_device.id === parseInt(waste_company_device_id)
    )

    state.waste_company_devices.splice(waste_company_device_index, 1)
  },
  SET_ASSIGN_HISTORY(state, history) {
    state.assign_history = history
  },
  SET_DEVICE(state, device) {
    state.device = device
  },
}

export const actions = {
  async fetch({ commit }, device_id) {
    let device = await this.$axios.$get(`${API_URL}/${device_id}`)

    commit('SET_DEVICE', device)

    return device
  },
  async update({ commit }, device) {
    device = await this.$axios.$put(`${API_URL}/${device.id}`, device)

    return device
  },
  async delete({ commit }, device_id) {
    await this.$axios.$delete(`${API_URL}/${device_id}`)
  },
  async assign({ commit }, { device_id, assignment }) {
    await this.$axios.$put(`${API_URL}/${device_id}/assign`, assignment)
  },
  async unassign({ commit }, device_id) {
    await this.$axios.$put(`${API_URL}/${device_id}/unassign`)
  },
  async fetchAssignHistory({ commit }, { device_id, params }) {
    let assignHistory = await this.$axios.$get(`${API_URL}/${device_id}/assign-history`, {
      params: params,
    })

    commit('SET_ASSIGN_HISTORY', assignHistory)

    return assignHistory
  },
  async updateNote({ commit }, { device_id, device_history_id, note }) {
    await this.$axios.$put(`${API_URL}/${device_id}/assign-history/${device_history_id}`, { note })
  },
}
