import { defineNuxtRouteMiddleware, useNuxtApp } from '#app'

export default defineNuxtRouteMiddleware(async () => {
  const { $store } = useNuxtApp()

  // Clear error message
  if ($store.getters.shouldClearError) {
    $store.dispatch('clearErrorMessage')
  } else {
    $store.dispatch('markErrorForClearing')
  }

  // Clear warning messages
  if ($store.getters.shouldClearWarnings) {
    $store.dispatch('clearWarningMessages')
  } else {
    $store.dispatch('markWarningForClearing')
  }

  // Clear success message
  if ($store.getters.shouldClearSuccess) {
    $store.dispatch('clearSuccessMessage')
  } else {
    $store.dispatch('markSuccessForClearing')
  }

  // Clear validation error message
  if ($store.getters['validation/shouldClear']) {
    $store.dispatch('validation/clearErrors')
  } else {
    $store.dispatch('validation/markForClearing')
  }
})
