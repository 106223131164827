export const state = () => ({
  translations: {
    datePicker: {
      translation: {
        labelPrevDecade: 'Minulé století',
        labelPrevYear: 'Minulý rok',
        labelPrevMonth: 'Minulý měsíc',
        labelCurrentMonth: 'Aktuální měsíc',
        labelNextMonth: 'Příští měsíc',
        labelNextYear: 'Příští rok',
        labelNextDecade: 'Příští století',
        labelToday: 'Dnes',
        labelSelected: 'Vyrané datum',
        labelNoDateSelected: 'Nebylo vybráno žádné datum',
        labelCalendar: 'Kalendár',
        labelNav: 'Navigace v kalendáři',
        labelHelp: 'Pro výběr data můžete použít šipky.',
      },
    },
  },
})

export const getters = {
  getDatePickerTranslations: state => {
    return state.translations.datePicker
  },
}

export const mutations = {}

export const actions = {}
