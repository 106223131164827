const API_URL = '/catalog'

export const state = () => ({
  waste_catalog: [],
  waste_collection_routes: [],
  workers: [],
  cars: [],
  enumData: [],
  waste_customers: [],
  bin_catalog: [],
  worker_positions: [],
  waste_company_branches: [],
  subjects: [],
  countries: [],
})

export const getters = {
  getAllWaste: state => {
    return state.waste_catalog
  },
  getAllWasteCollectionRoutes: state => {
    return state.waste_collection_routes
  },
  getAllWorkers: state => {
    return state.workers
  },
  getAllCars: state => {
    return state.cars
  },
  getEnum: state => name => {
    return state.enumData[name] || null
  },
  getAllWasteCustomers: state => {
    return state.waste_customers
  },
  getWasteCustomer: state => waste_customer_id => {
    return state.waste_customers.find(waste_customer => waste_customer.id === waste_customer_id)
  },
  getAllBinTypes: state => {
    return state.bin_catalog
  },
  getAllWorkerPositions: state => {
    return state.worker_positions
  },
  getAllWasteCompanyBranches: state => {
    return state.waste_company_branches
  },
  getAllSubjects: state => {
    return state.subjects
  },
  getAllCountries: state => {
    return state.countries
  },
  getCountryById: state => id => {
    return state.countries.find(country => country.id === id)
  },
}

export const mutations = {
  SET_WASTE_CATALOG(state, waste_catalog) {
    state.waste_catalog = waste_catalog
  },
  SET_BIN_CATALOG(state, bin_catalog) {
    state.bin_catalog = bin_catalog
  },
  SET_WASTE_COLLECTION_ROUTES(state, waste_collection_routes) {
    state.waste_collection_routes = waste_collection_routes
  },
  SET_WORKERS(state, workers) {
    state.workers = workers
  },
  SET_CARS(state, cars) {
    state.cars = cars
  },
  SET_ENUM(state, { data, enumType }) {
    state.enumData[enumType] = data
  },
  SET_WASTE_CUSTOMERS(state, customers) {
    state.waste_customers = customers
  },
  SET_WORKER_POSITIONS(state, positions) {
    state.worker_positions = positions
  },
  SET_WASTE_COMPANY_BRANCHES(state, branches) {
    state.waste_company_branches = branches
  },
  SET_SUBJECTS(state, subjects) {
    state.subjects = subjects
  },
  SET_COUNTRIES(state, countries) {
    state.countries = countries
  },
}

export const actions = {
  async fetchAllWaste({ commit }, { search, selectedIds, wasteCompanyBranchId }) {
    let waste_catalog = await this.$axios.$get(`${API_URL}/${wasteCompanyBranchId}/waste-catalog`, {
      params: { searchQuery: search, selectedIds },
    })

    commit('SET_WASTE_CATALOG', waste_catalog.items)

    return waste_catalog.items
  },
  async fetchAllWasteCollectionRoutes({ commit }, { search, wasteCompanyBranchId }) {
    let waste_collection_routes = await this.$axios.$get(`${API_URL}/${wasteCompanyBranchId}/waste-collection-route`, {
      params: { searchQuery: search },
    })

    commit('SET_WASTE_COLLECTION_ROUTES', waste_collection_routes.items)

    return waste_collection_routes.items
  },
  async fetchAllWorkers({ commit }, { search, wasteCompanyBranchId }) {
    let workers = await this.$axios.$get(`${API_URL}/${wasteCompanyBranchId}/worker`, {
      params: { searchQuery: search },
    })

    commit('SET_WORKERS', workers.items)

    return workers.items
  },
  async fetchAllCars({ commit }, { search, wasteCompanyBranchId }) {
    let cars = await this.$axios.$get(`${API_URL}/${wasteCompanyBranchId}/car`, {
      params: { searchQuery: search },
    })

    commit('SET_CARS', cars.items)

    return cars.items
  },
  async fetchEnum({ commit }, enumType) {
    let data = await this.$axios.$get(API_URL + '/enum', {
      params: { type: enumType },
    })

    // move Sunday to the end
    if (enumType === 'DayOfWeek' && data.Sunday) {
      let sortedDays = {}
      for (let key in data) {
        if (key !== 'Sunday') {
          sortedDays[key] = data[key]
        }
      }
      sortedDays.Sunday = data.Sunday
      data = sortedDays
    }

    commit('SET_ENUM', { data, enumType })

    return data
  },
  async fetchAllWasteCustomers({ commit }, { search, wasteCompanyBranchId, selectedIds }) {
    let customers = await this.$axios.$get(`${API_URL}/${wasteCompanyBranchId}/waste-customer`, {
      params: { searchQuery: search, selectedIds: selectedIds ?? [] },
    })

    commit('SET_WASTE_CUSTOMERS', customers.items)

    return customers.items
  },
  async fetchAllBinTypes({ commit }) {
    let bin_catalog = await this.$axios.$get(API_URL + '/bin-catalog')

    commit('SET_BIN_CATALOG', bin_catalog.items)

    return bin_catalog.items
  },
  async fetchAllWorkerPositions({ commit }, { search, wasteCompanyBranchId }) {
    let positions = await this.$axios.$get(`${API_URL}/${wasteCompanyBranchId}/worker-positions`, {
      params: { searchQuery: search },
    })

    commit('SET_WORKER_POSITIONS', positions.items)

    return positions.items
  },
  async fetchAllWasteCompanyBranches({ commit }, { search }) {
    let branches = await this.$axios.$get(`${API_URL}/waste-company-branch`, {
      params: { searchQuery: search },
    })

    commit('SET_WASTE_COMPANY_BRANCHES', branches.items)

    return branches.items
  },
  async fetchAllSubjects({ commit }, { wasteCompanyBranchId, wasteCustomerId, search, selectedIds }) {
    let subjects = await this.$axios.$get(
      `${API_URL}/${wasteCompanyBranchId}/wastecustomer/${wasteCustomerId}/subject`,
      {
        params: { searchQuery: search, selectedIds: selectedIds ?? [] },
      }
    )

    commit('SET_SUBJECTS', subjects.items)

    return subjects.items
  },
  async fetchAllCountries({ commit }, { search = null }) {
    let countries = await this.$axios.$get(`${API_URL}/country`, {
      params: { searchQuery: search },
    })

    commit('SET_COUNTRIES', countries.items)

    return countries.items
  },
}
