const API_URL = '/waste-evidence-header'

export const state = () => ({
  waste_evidence_headers: [],
  dashboard_data: null,
  waste_collection_logs: [],
  waste_collection_routes: [],
  route_id_filter: null,
})

export const getters = {
  getAll: state => {
    return state.waste_evidence_headers
  },
  get: state => waste_evidence_header_id => {
    return state.waste_evidence_headers.find(
      waste_evidence_header => waste_evidence_header.id === parseInt(waste_evidence_header_id)
    )
  },
  getRouteIdFilter: state => {
    return state.route_id_filter
  },
}

export const mutations = {
  SET_WASTE_EVIDENCE_HEADERS(state, waste_evidence_headers) {
    state.waste_evidence_headers = waste_evidence_headers
  },
  PUSH_OR_REPLACE_WASTE_EVIDENCE_HEADER(state, new_waste_evidence_header) {
    let waste_evidence_header_index = state.waste_evidence_headers.findIndex(
      waste_evidence_header => waste_evidence_header.id === parseInt(new_waste_evidence_header.id)
    )

    if (waste_evidence_header_index >= 0) {
      state.waste_evidence_headers.splice(waste_evidence_header_index, 1, new_waste_evidence_header)
    } else {
      state.waste_evidence_headers.push(new_waste_evidence_header)
    }
  },
  REMOVE_WASTE_EVIDENCE_HEADER(state, waste_evidence_header_id) {
    let waste_evidence_header_index = state.waste_evidence_headers.findIndex(
      waste_evidence_header => waste_evidence_header.id === parseInt(waste_evidence_header_id)
    )

    state.waste_evidence_headers.splice(waste_evidence_header_index, 1)
  },
  SET_WASTE_EVIDENCE_HEADER_SET_CUSTOMER(state, { waste_evidence_header_id, waste_customer_name }) {
    let waste_evidence_header_index = state.waste_evidence_headers.findIndex(
      waste_evidence_header => waste_evidence_header.id === parseInt(waste_evidence_header_id)
    )

    if (waste_evidence_header_index >= 0) {
      state.waste_evidence_headers[waste_evidence_header_index].customer = waste_customer_name
    }
  },
  SET_WASTE_EVIDENCE_HEADER_DASHBOARD_DATA(state, dashboardData) {
    state.dashboard_data = dashboardData
  },
  SET_WASTE_COLLECTION_LOGS(state, logs) {
    state.waste_collection_logs = logs
  },
  SET_WASTE_COLLECTION_ROUTES(state, routes) {
    state.waste_collection_routes = routes
  },
  SET_ROUTE_ID_FILTER(state, id) {
    state.route_id_filter = id
  },
}

export const actions = {
  async fetch({ commit }, waste_evidence_header_id) {
    let waste_evidence_header = await this.$axios.$get(API_URL + '/' + waste_evidence_header_id)

    commit('PUSH_OR_REPLACE_WASTE_EVIDENCE_HEADER', waste_evidence_header)

    return waste_evidence_header
  },
  async update({ commit }, { waste_evidence_header, waste_evidence_header_id }) {
    waste_evidence_header = await this.$axios.$put(API_URL + '/' + waste_evidence_header_id, waste_evidence_header)

    commit('PUSH_OR_REPLACE_WASTE_EVIDENCE_HEADER', waste_evidence_header)

    return waste_evidence_header
  },
  async delete({ commit }, waste_evidence_header_id) {
    await this.$axios.$delete(API_URL + '/' + waste_evidence_header_id)

    commit('REMOVE_WASTE_EVIDENCE_HEADER', waste_evidence_header_id)
  },
  async setWasteCustomer({ commit }, { waste_evidence_header_id, waste_customer_id, waste_customer_name }) {
    await this.$axios.$patch(`${API_URL}/${waste_evidence_header_id}/waste-customer/${waste_customer_id}`)

    commit('SET_WASTE_EVIDENCE_HEADER_SET_CUSTOMER', {
      waste_evidence_header_id,
      waste_customer_name,
    })
  },
  async findByBarcode({ commit }, barcode) {
    return await this.$axios.$get(API_URL + '/find-by-barcode', {
      params: { barcode: barcode },
    })
  },
  async fetchDashboardData({ commit }, waste_evidence_header_id) {
    let dashboardData = await this.$axios.$get(`${API_URL}/${waste_evidence_header_id}/dashboard`)

    commit('SET_WASTE_EVIDENCE_HEADER_DASHBOARD_DATA', dashboardData)

    return dashboardData
  },
  async fetchWasteCollectionLogs({ commit }, { waste_evidence_header_id, params }) {
    let wasteCollectionLogs = await this.$axios.$get(`${API_URL}/${waste_evidence_header_id}/waste-collection`, {
      params: params,
    })

    commit('SET_WASTE_COLLECTION_LOGS', wasteCollectionLogs)

    return wasteCollectionLogs
  },
  async fetchWasteCollectionRoutes({ commit }, { waste_evidence_header_id, params }) {
    let wasteCollectionRoutes = await this.$axios.$get(
      `${API_URL}/${waste_evidence_header_id}/waste-collection-route`,
      { params: params }
    )

    commit('SET_WASTE_COLLECTION_ROUTES', wasteCollectionRoutes)

    return wasteCollectionRoutes
  },
  async addBinToWasteCollectionRoute({ commit }, { waste_collection_route_id, waste_evidence_header_id }) {
    await this.$axios.$patch(
      `${API_URL}/${waste_evidence_header_id}/waste-collection-route/${waste_collection_route_id}`
    )
  },
  setRouteIdFilter({ commit }, id) {
    commit('SET_ROUTE_ID_FILTER', id)
  },
}
